import { useHttpClient } from '../hooks/useHttpClient';
import { Button } from 'primereact/button';
import React from 'react';

const DescargarKuDE = (props) => {
    const httpClient = useHttpClient();
    const [isLoading, setIsLoading] = React.useState(false);

    const handleDownload = () => {
        setIsLoading(true);
        httpClient
            .get(`/api/de/${props.edoc.id}/kude`, { headers: { 'Content-Type': 'blob' }, responseType: 'arraybuffer' })
            .then((response) => {
                setIsLoading(false);
                let fileName = `${props.edoc.id}.pdf`;
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: 'application/pdf',
                        encoding: 'UTF-8'
                    })
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    };

    return <Button type="button" icon="pi pi-file-pdf" loading={isLoading} className={props.className} tooltip="Descargar KuDE" onClick={handleDownload} />;
};

export default DescargarKuDE;
