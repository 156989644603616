import { createSlice } from '@reduxjs/toolkit';

export const sessionReducer = createSlice({
    name: 'session',
    initialState: {
        username: '',
        accesos: [],
        selectedIssuer: {},
        rol: ''
    },
    reducers: {
        login: (state, action) => {
            state.username = action.payload.username;
            state.accesos = action.payload.accesos;
            state.rol = action.payload.rol;
        },
        logout: (state) => {
            state.username = '';
            state.accesos = [];
            state.selectedIssuer = '';
            state.rol = '';
        },
        selectIssuer: (state, action) => {
            state.selectedIssuer = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { login, logout, selectIssuer } = sessionReducer.actions;

export default sessionReducer.reducer;
