import { useHttpClient } from '../hooks/useHttpClient';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { isEmpty } from 'lodash';
import { FloatLabel } from 'primereact/floatlabel';

const CancelarDocumentosElectronicos = (props) => {
    const httpClient = useHttpClient();
    const [visible, setVisible] = useState(false);
    const [reason, setReason] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const accept = () => {
        setIsLoading(true);
        const body = {
            motivo: reason
        };
        httpClient
            .delete(`/api/de/${props.edoc.id}`, { data: body })
            .then((response) => {
                props.onSuccess(response);
                setIsLoading(false);
                setVisible(false);
                setReason('');
            })
            .catch((error) => {
                props.onError(error);
                setIsLoading(false);
                setVisible(false);
                setReason('');
            });
    };

    const reject = () => {};

    const confirmCancellation = () => {
        confirmDialog({
            message: 'Está acción enviará un evento de cancelación a SIFEN. ¿Está seguro/a de realizar esta acción?',
            header: 'Atención',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Si',
            accept,
            reject
        });
    };

    const getFormErrorMessage = () => {
        return isEmpty(reason) ? <small className="p-error">Campo obligatorio</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <div>
            <Dialog
                header="Cancelar Documento Electrónico"
                visible={visible}
                modal
                style={{ width: '400px', height: '220px' }}
                onHide={() => {
                    setVisible(false);
                }}
            >
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-12" style={{ marginTop: '1rem' }}>
                        <FloatLabel>
                            <InputText id="motivo" type="text" value={reason} onChange={(e) => setReason(e.target.value)} className={classNames({ 'p-invalid': isEmpty(reason) })} />
                            <label htmlFor="motivo">Motivo</label>
                            {getFormErrorMessage()}
                        </FloatLabel>
                    </div>
                </div>
                <div className="flex flex-wrap justify-content-end">
                    <Button type="button" loading={isLoading} className="field col-12 md:col-3" disabled={isEmpty(reason)} onClick={() => confirmCancellation()} label="Guardar" />
                </div>
            </Dialog>
            <Button type="button" icon="pi pi-ban" loading={isLoading} className={props.className} tooltip="Cancelar" onClick={() => setVisible(true)} />
        </div>
    );
};

export default CancelarDocumentosElectronicos;
