import { createSlice } from '@reduxjs/toolkit';

export const edocReducer = createSlice({
    name: 'edoc',
    initialState: {
        fromDate: '',
        toDate: '',
        cdc: '',
        numDoc: '',
        issuer: { code: '', name: '' },
        status: null,
        tipoDoc: null,
        selectedEdoc: {}
    },
    reducers: {
        updateEdocFilters: (state, action) => {
            state.fromDate = action.payload.fromDate;
            state.toDate = action.payload.toDate;
            state.cdc = action.payload.cdc;
            state.numDoc = action.payload.numDoc;
            state.issuer = action.payload.issuer;
            state.status = action.payload.status;
            state.tipoDoc = action.payload.tipoDoc;
        },
        updateFromDate: (state, action) => {
            state.fromDate = action.payload.fromDate;
        },
        updateToDate: (state, action) => {
            state.toDate = action.payload.toDate;
        },
        updateCDC: (state, action) => {
            state.cdc = action.payload.cdc;
        },
        updateNumDoc: (state, action) => {
            state.numDoc = action.payload.numDoc;
        },
        updateIssuer: (state, action) => {
            state.issuer = action.payload.issuer;
        },
        updateStatus: (state, action) => {
            state.status = action.payload.status;
        },
        updateTipoDoc: (state, action) => {
            state.tipoDoc = action.payload.tipoDoc;
        },
        selectEdoc: (state, action) => {
            state.selectedEdoc = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { updateEdocFilters, updateFromDate, updateToDate, updateCDC, updateNumDoc, updateIssuer, updateStatus, updateTipoDoc, selectEdoc } = edocReducer.actions;

export default edocReducer.reducer;
