export const LISTA_FORMA_PROC_PAGO = [
    {
        i_for_pro_pa: 1,
        d_des_for_pra: 'POST'
    },
    {
        i_for_pro_pa: 1,
        d_des_for_pra: 'Pago Electrónico'
    }
];

export const LISTA_TIPO_PAGO = [
    {
        i_ti_pago: 1,
        d_des_ti_pag: 'Efectivo'
    },
    {
        i_ti_pago: 2,
        d_des_ti_pag: 'Cheque'
    },
    {
        i_ti_pago: 3,
        d_des_ti_pag: 'Tarjeta de crédito'
    },
    {
        i_ti_pago: 4,
        d_des_ti_pag: 'Tarjeta de débito'
    },
    {
        i_ti_pago: 5,
        d_des_ti_pag: 'Transferencia'
    },
    {
        i_ti_pago: 6,
        d_des_ti_pag: 'Giro'
    },
    {
        i_ti_pago: 7,
        d_des_ti_pag: 'Billetera electrónica'
    },
    {
        i_ti_pago: 8,
        d_des_ti_pag: 'Tarjeta empresarial'
    },
    {
        i_ti_pago: 9,
        d_des_ti_pag: 'Vale'
    },
    {
        i_ti_pago: 10,
        d_des_ti_pag: 'Retención'
    },
    {
        i_ti_pago: 11,
        d_des_ti_pag: 'Pago por anticipo'
    },
    {
        i_ti_pago: 12,
        d_des_ti_pag: 'Valor fiscal'
    },
    {
        i_ti_pago: 13,
        d_des_ti_pag: 'Valor comercial'
    },
    {
        i_ti_pago: 14,
        d_des_ti_pag: 'Compensación'
    },
    {
        i_ti_pago: 15,
        d_des_ti_pag: 'Permuta'
    },
    {
        i_ti_pago: 16,
        d_des_ti_pag: 'Pago bancario'
    },
    {
        i_ti_pago: 17,
        d_des_ti_pag: 'Pago Móvil'
    },
    {
        i_ti_pago: 18,
        d_des_ti_pag: 'Donación'
    },
    {
        i_ti_pago: 19,
        d_des_ti_pag: 'Promoción'
    },
    {
        i_ti_pago: 20,
        d_des_ti_pag: 'Consumo Interno'
    },
    {
        i_ti_pago: 21,
        d_des_ti_pag: 'Pago Electrónico'
    }
    // {
    //     i_ti_pago: 99,
    //     d_des_ti_pag: 'Otro' No soportado hasta el momento
    // }
];
