import { useHttpClient } from '../../hooks/useHttpClient';
import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { isEmpty } from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { format } from 'date-fns';

const Timbrado = (props) => {
    const httpClient = useHttpClient();
    const [timbrados, setTimbrados] = useState([]);
    const [loading, setLoading] = useState(true);
    const [didMount, setDidMount] = useState(false);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [filters] = useState(null);
    const toast = useRef(null);
    const [tiposDE] = useState([
        'Factura electrónica',
        'Factura electrónica de exportación',
        'Factura electrónica de importación',
        'Autofactura electrónica',
        'Nota de crédito electrónica',
        'Nota de débito electrónica',
        'Nota de remisión electrónica',
        'Comprobante de retención electrónico'
    ]);

    const isNumber = (value) => {
        return !isNaN(parseFloat(value)) && isFinite(value);
    };

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    useEffect(() => {
        if (!isEmpty(props.issuer) && !didMount) {
            setDidMount(true);
            formik.setFieldValue('owner', props.issuer);
            triggerLoad();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.issuer, didMount]);

    const triggerLoad = () => {
        setLoading(true);
        httpClient
            .get(`admin/emisor/timbrados/${props.issuer}`)
            .then((response) => {
                setTimbrados(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const dropdownTipoDE = [
        { name: 'Factura electrónica', code: 1 },
        { name: 'Factura electrónica de exportación', code: 2 },
        { name: 'Factura electrónica de importación', code: 3 },
        { name: 'Autofactura electrónica', code: 4 },
        { name: 'Nota de crédito electrónica', code: 5 },
        { name: 'Nota de débito electrónica', code: 6 },
        { name: 'Nota de remisión electrónica', code: 7 },
        { name: 'Comprobante de retención electrónico', code: 8 }
    ];

    const validationSchema = Yup.object().shape({
        owner: Yup.string().required('Campo requerido'),
        tipo_de: Yup.object({
            name: Yup.string(),
            code: Yup.number()
        }),
        nro_timbrado: Yup.string().min(8, 'Debe tener al menos 8 caracteres').max(8, 'Debe tener como máximo 8 caracteres').required('Campo requerido'),
        establecimiento: Yup.number().required('Campo requerido').positive('Campo solo debe contener números positivos').max(999, 'Campo no puede ser mayor 999'),
        punto_expedicion: Yup.number().required('Campo requerido').positive('Campo solo debe contener números positivos').max(999, 'Campo no puede ser mayor 999'),
        nro_documento: Yup.number().optional(),
        serie: Yup.string().optional().length(2, 'Debe tener tener 2 caracteres no numéricos'),
        fecha_inicio: Yup.date().required('Fecha inicio de timbrado es requerido')
    });

    const onSubmit = (values, convert) => {
        const timbrado = {
            ...values,
            tipo_de: values.tipo_de.code,
            fecha_inicio: format(values.fecha_inicio, 'yyyy-MM-dd')
        };
        submit(timbrado);
    };

    const submit = (timbrado) => {
        setLoading(true);
        httpClient
            .post('admin/emisor/timbrado', timbrado)
            .then(() => {
                setLoading(false);
                setDisplayDialog(false);
                toast.current.show({ severity: 'success', summary: 'Timbrado creado correctamente', life: 10000 });
                triggerLoad();
            })
            .catch((error) => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error al crear el timbrado', detail: error.response.data.error, life: 10000 });
            });
    };

    const formik = useFormik({
        initialValues: {
            owner: '',
            tipo_de: dropdownTipoDE[0],
            nro_timbrado: '',
            establecimiento: 1,
            punto_expedicion: 1,
            nro_documento: 0,
            serie: '',
            fecha_inicio: ''
        },
        validationSchema,
        onSubmit
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const obtenerCodeDE = (value) => {
        switch (value) {
            case 'Factura electrónica':
                return 1;
            case 'Factura electrónica de exportación':
                return 2;
            case 'Factura electrónica de importación':
                return 3;
            case 'Autofactura electrónica':
                return 4;
            case 'Nota de crédito electrónica':
                return 5;
            case 'Nota de débito electrónica':
                return 6;
            case 'Nota de remisión electrónica':
                return 7;
            case 'Comprobante de retención electrónico':
                return 8;
            default:
                return 0;
        }
    };

    const obtenerDescDE = (value) => {
        switch (value) {
            case 1:
                return 'Factura electrónica';
            case 2:
                return 'Factura electrónica de exportación';
            case 3:
                return 'Factura electrónica de importación';
            case 4:
                return 'Autofactura electrónica';
            case 5:
                return 'Nota de crédito electrónica';
            case 6:
                return 'Nota de débito electrónica';
            case 7:
                return 'Nota de remisión electrónica';
            case 8:
                return 'Comprobante de retención electrónico';
            default:
                return 'Tipo Documento Electrónico inválido';
        }
    };

    const header = () => {
        return (
            <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                <span className="text-xl text-900 font-bold">Timbrados</span>
                <Button icon="pi pi-plus" onClick={() => setDisplayDialog(true)} />
            </div>
        );
    };

    const tipoDEBodyTemplate = (rowData) => {
        return obtenerDescDE(rowData?.tipo_de);
    };

    const tipoDEEditor = (options) => {
        let value = options.value;
        if (isNumber(value)) {
            value = obtenerDescDE(value);
        }
        return <Dropdown value={value} options={tiposDE} onChange={(e) => options.editorCallback(obtenerCodeDE(e.value))} placeholder="Seleccione un tipo de DE" />;
    };

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const numberEditor = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.editorCallback(e.value)}></InputNumber>;
    };

    const calendarEditor = (options) => {
        const date = new Date(options.value);
        const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        return (
            <Calendar
                value={utcDate}
                onChange={(e) => {
                    options.editorCallback(e.value);
                }}
                dateFormat="yy-mm-dd"
                locale="es"
                selectionMode={'single'}
            />
        );
    };

    const allowEdit = (rowData) => {
        return true;
    };

    const onRowEditComplete = (e) => {
        const timbrado = e.newData;

        const date = new Date(timbrado.fecha_inicio);
        const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

        timbrado.fecha_inicio = format(utcDate, 'yyyy-MM-dd');
        submit(timbrado);
    };

    return (
        <React.Fragment>
            <div className="col-12">
                <Toast ref={toast} />
                <div className="card">
                    <DataTable
                        value={timbrados}
                        header={header}
                        className="mt-3"
                        filters={filters}
                        filterDisplay="row"
                        editMode="row"
                        loading={loading}
                        stripedRows
                        responsiveLayout="stack"
                        emptyMessage="No existen timbrados"
                        onRowEditComplete={onRowEditComplete}
                    >
                        <Column field="tipo_de" header="Tipo" sortable body={tipoDEBodyTemplate} editor={(options) => tipoDEEditor(options)} style={{ maxWidth: '5%' }} />
                        <Column field="fecha_inicio" header="Fecha inicio timbrado" editor={(options) => calendarEditor(options)} sortable style={{ minWidth: '15%' }} />
                        <Column field="nro_timbrado" header="Nro. Timbrado" editor={(options) => textEditor(options)} sortable style={{ maxWidth: '2%' }} />
                        <Column field="establecimiento" header="Establecimiento" editor={(options) => numberEditor(options)} sortable style={{ maxWidth: '1%' }} />
                        <Column field="punto_expedicion" header="Punto Expedición" editor={(options) => numberEditor(options)} sortable style={{ maxWidth: '1%' }} />
                        <Column field="serie" header="Serie" editor={(options) => textEditor(options)} sortable style={{ maxWidth: '1%' }} />
                        <Column field="nro_documento" header="Nro. Documento" editor={(options) => numberEditor(options)} sortable style={{ maxWidth: '1%' }} />
                        <Column rowEditor={allowEdit} headerStyle={{ maxWidth: '2%' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>
                </div>
            </div>

            <Dialog visible={displayDialog} header="Agregar timbrado" style={{ maxWidth: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} onHide={() => setDisplayDialog(false)}>
                <div className="grid">
                    <div className="col-12">
                        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-1">
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-6" style={{ marginTop: '1rem' }}>
                                    <span className="p-float-label">
                                        <Dropdown
                                            id="tipoDE"
                                            value={formik.values.tipo_de}
                                            onChange={(e) => formik.setFieldValue('tipo_de', e.value)}
                                            onBlur={() => formik.setFieldTouched('tipo_de', true)}
                                            options={dropdownTipoDE}
                                            optionLabel="name"
                                            placeholder="Seleccione el tipo de documento electrónico"
                                            className={classNames({ 'p-invalid': isFormFieldInvalid('tipo_de') })}
                                        />
                                        <label htmlFor="tipoDE">Tipo Documento Electrónico</label>
                                        {getFormErrorMessage('tipo_contribuyente')}
                                    </span>
                                </div>
                                <div className="col-12 md:col-6" style={{ marginTop: '1rem' }}>
                                    <span className="p-float-label">
                                        <InputText
                                            id="emisor"
                                            type="text"
                                            value={formik.values.owner}
                                            onChange={(e) => formik.setFieldValue('owner', e.target.value)}
                                            onBlur={() => formik.setFieldTouched('owner', true)}
                                            className={classNames({ 'p-invalid': isFormFieldInvalid('owner') })}
                                        />
                                        <label htmlFor="emisor">Emisor</label>
                                        {getFormErrorMessage('owner')}
                                    </span>
                                </div>
                                <div className="field col-12 md:col-6" style={{ marginTop: '0.5rem' }}>
                                    <span className="p-float-label">
                                        <InputText
                                            id="nroTimbrado"
                                            type="text"
                                            value={formik.values.nro_timbrado}
                                            onChange={(e) => formik.setFieldValue('nro_timbrado', e.target.value)}
                                            onBlur={() => formik.setFieldTouched('nro_timbrado', true)}
                                            className={classNames({ 'p-invalid': isFormFieldInvalid('nro_timbrado') })}
                                        />
                                        <label htmlFor="nroTimbrado">Nro. Timbrado</label>
                                        {getFormErrorMessage('nro_timbrado')}
                                    </span>
                                </div>
                                <div className="field col-12 md:col-6" style={{ marginTop: '0.5rem' }}>
                                    <span className="p-float-label">
                                        <InputNumber
                                            id="establecimiento"
                                            value={formik.values.establecimiento}
                                            onChange={(e) => formik.setFieldValue('establecimiento', e.value)}
                                            onBlur={() => formik.setFieldTouched('establecimiento', true)}
                                            className={classNames({ 'p-invalid': isFormFieldInvalid('establecimiento') })}
                                        />
                                        <label htmlFor="establecimiento">Establecimiento</label>
                                        {getFormErrorMessage('establecimiento')}
                                    </span>
                                </div>
                                <div className="field col-12 md:col-6" style={{ marginTop: '0.5rem' }}>
                                    <span className="p-float-label">
                                        <InputNumber
                                            id="puntoExpedicion"
                                            value={formik.values.punto_expedicion}
                                            onChange={(e) => formik.setFieldValue('punto_expedicion', e.value)}
                                            onBlur={() => formik.setFieldTouched('punto_expedicion', true)}
                                            className={classNames({ 'p-invalid': isFormFieldInvalid('punto_expedicion') })}
                                        />
                                        <label htmlFor="puntoExpedicion">Punto de expedición</label>
                                        {getFormErrorMessage('punto_expedicion')}
                                    </span>
                                </div>
                                <div className="field col-12 md:col-6" style={{ marginTop: '0.5rem' }}>
                                    <span className="p-float-label">
                                        <InputNumber
                                            id="nroDocumento"
                                            value={formik.values.nro_documento}
                                            onChange={(e) => formik.setFieldValue('nro_documento', e.value)}
                                            onBlur={() => formik.setFieldTouched('nro_documento', true)}
                                            className={classNames({ 'p-invalid': isFormFieldInvalid('nro_documento') })}
                                        />
                                        <label htmlFor="nroDocumento">Nro. Documento</label>
                                        {getFormErrorMessage('nro_documento')}
                                    </span>
                                </div>
                                <div className="field col-12 md:col-6" style={{ marginTop: '0.5rem' }}>
                                    <span className="p-float-label">
                                        <InputText
                                            id="serie"
                                            type="text"
                                            value={formik.values.serie}
                                            onChange={(e) => formik.setFieldValue('serie', e.target.value)}
                                            onBlur={() => formik.setFieldTouched('serie', true)}
                                            className={classNames({ 'p-invalid': isFormFieldInvalid('serie') })}
                                        />
                                        <label htmlFor="serie">Serie</label>
                                        {getFormErrorMessage('serie')}
                                    </span>
                                </div>
                                <div className="field col-12 md:col-6" style={{ marginTop: '0.5rem' }}>
                                    <span className="p-float-label">
                                        <Calendar
                                            id="fechaInicioTimb"
                                            value={formik.values.fecha_inicio}
                                            onChange={(e) => {
                                                formik.setFieldValue('fecha_inicio', e.value);
                                            }}
                                            dateFormat="yy-mm-dd"
                                            locale="es"
                                            selectionMode={'single'}
                                        />
                                        <label htmlFor="fechaInicioTimb">Fecha de inicio del timbrado</label>
                                        {getFormErrorMessage('fecha_inicio')}
                                    </span>
                                </div>
                            </div>

                            <div className="flex flex-wrap justify-content-end gap-2">
                                <Button type="button" className=" p-button-danger field col-12 md:col-2" onClick={() => setDisplayDialog(false)} label="Cancelar" />
                                <Button type="submit" className="field col-12 md:col-2" label="Guardar" />
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default Timbrado;
