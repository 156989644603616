import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useHttpClient } from '../../hooks/useHttpClient';
import ActualizarCertificado from '../../components/ActualizarCertificado';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

const InsertarCertificado = () => {
    const navigate = useNavigate();
    const [issuer, setIssuer] = useState({});

    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const toast = useRef(null);
    const [isloading, setIsLoading] = useState(false);

    const httpClient = useHttpClient();

    useEffect(() => {
        if (!isEmpty(token)) {
            setIsLoading(true);
            httpClient
                .post('/admin/emisor/decode/token', { token: token })
                .then((response) => {
                    setIssuer(response.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    navigate('/');
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const onCertUpdateError = (error) => {
        toast.current.show({ severity: 'error', summary: 'Error al agregar certificado', detail: error.response.data.error, life: 3000 });
    };

    return (
        <div className="h-screen flex flex-column bg-cover" style={{ backgroundImage: 'url(/layout/images/pages/login-bg.jpg)' }}>
            <Toast ref={toast} />
            <div className="align-self-center mt-auto mb-auto">
                <div className="pages-panel card flex flex-column align-items-center" style={{ maxWidth: '500px' }}>
                    <h6>{isloading ? <Skeleton height="2rem" width="15rem" className="mb-2"></Skeleton> : issuer.razon_social}</h6>
                    <h7>Por favor actualice su certificado</h7>
                    <ActualizarCertificado ruc={issuer.ruc} onError={onCertUpdateError} onSuccess={() => navigate('/')} url="admin/public/emisor/actualizar/cert/" />
                </div>
            </div>
        </div>
    );
};

export default InsertarCertificado;
