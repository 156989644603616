import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useHttpClient } from '../hooks/useHttpClient';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';

const CambiarClave = (props) => {
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isBlocked, setIsBlocket] = useState(false);

    const httpClient = useHttpClient();

    useEffect(() => {
        if (password !== repeatPassword) {
            setIsValid(false);
            return;
        }

        if (isEmpty(password) || isEmpty(repeatPassword)) {
            setIsValid(false);
            return;
        }

        setIsValid(true);
    }, [password, repeatPassword]);

    const updatePassword = () => {
        setIsBlocket(true);
        const body = {
            nombre_usuario: props.nombreUsuario,
            nueva_clave: password
        };

        httpClient
            .post('/admin/usuario/cambiarClave', body)
            .then(() => {
                setIsBlocket(false);
                props.onSuccess();
            })
            .catch(() => {
                setIsBlocket(false);
            });
    };

    const getFormErrorMessage = () => {
        return !isValid ? <small className="p-error">Clave inválida</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <BlockUI blocked={isBlocked}>
            <div className="card">
                <div className="col-12">
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-12">
                            <label htmlFor="clave1">Clave</label>
                            <Password
                                id="clave1"
                                type="text"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className={classNames({ 'p-invalid': !isValid })}
                                promptLabel="Por favor ingrese una clave"
                                weakLabel="Débil"
                                mediumLabel="Medio"
                                strongLabel="Robusta"
                                autoComplete="new-password"
                                toggleMask
                            />
                            {getFormErrorMessage()}
                        </div>
                        <div className="field col-12 md:col-12">
                            <label htmlFor="clave2">Repita la clave</label>
                            <Password
                                id="clave2"
                                type="text"
                                value={repeatPassword}
                                onChange={(e) => setRepeatPassword(e.target.value)}
                                className={classNames({ 'p-invalid': !isValid })}
                                weakLabel="Débil"
                                mediumLabel="Medio"
                                autoComplete="new-password"
                                strongLabel="Robusta"
                                toggleMask
                            />
                            {getFormErrorMessage()}
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-content-end">
                        <Button type="submit" className="field col-12 md:col-3" disabled={!isValid} onClick={() => updatePassword()} label="Guardar" />
                    </div>
                </div>
            </div>
        </BlockUI>
    );
};

export default CambiarClave;
