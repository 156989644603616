import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import App from './App';
import Error from './pages/Error';
import NotFound from './pages/NotFound';
import RouteGuard from './components/RouteGuard';
import Login from './pages/Login/Login';
import InsertarCertificado from './pages/InsertarCertificado/InsertarCertificado';
import EmailCambiarClave from './pages/EmailCambiarClave/EmailCambiarClave';
import CambiarClaveUsuario from './pages/CambiarClaveUsuario/CambiarClaveUsuario';
import { LayoutProvider } from './LayoutContext';
import { PrimeReactProvider } from 'primereact/api';

const AppWrapper = (props) => {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <PrimeReactProvider>
            <LayoutProvider>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/update/cert" element={<InsertarCertificado />} />
                    <Route path="/enviar/email" element={<EmailCambiarClave />} />
                    <Route path="/actualizar/clave" element={<CambiarClaveUsuario />} />
                    <Route path="/error" element={<Error />} />
                    <Route path="/notfound" element={<NotFound />} />
                    {/*<Route path="/access" element={<Access />} />*/}
                    {/*<Route path="/landing" element={<Landing />} />*/}
                    <Route
                        path="/*"
                        element={
                            <RouteGuard>
                                <App />
                            </RouteGuard>
                        }
                    />
                </Routes>
            </LayoutProvider>
        </PrimeReactProvider>
    );
};

export default AppWrapper;
