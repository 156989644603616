import { useHttpClient } from '../hooks/useHttpClient';
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Dialog } from 'primereact/dialog';
import XMLViewer from 'react-xml-viewer';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';

const XMLInspector = (props) => {
    const httpClient = useHttpClient();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [xmlFile, setXMLFile] = useState('');

    const customTheme = {
        attributeKeyColor: '#0074D9',
        attributeValueColor: '#2ECC40'
    };

    const getXML = () => {
        if (!isEmpty(props.edoc.id)) {
            setLoading(true);
            httpClient
                .get(`/api/de/${props.edoc.id}/xml`, { headers: { 'Content-Type': 'application/xml' } })
                .then((response) => {
                    setXMLFile(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                });
        }
    };

    return (
        <div>
            <Dialog
                header="Archivo XML"
                visible={visible}
                modal
                closable={true}
                closeOnEscape={true}
                style={{ width: '55vw' }}
                onHide={() => {
                    setVisible(false);
                    setXMLFile('');
                }}
                onShow={getXML}
            >
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-12">{loading ? <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar> : <XMLViewer xml={xmlFile} indentSize={3} customTheme={customTheme} collapsible />}</div>
                </div>
            </Dialog>
            <Button type="button" icon="pi pi-eye" loading={loading} className={props.className} tooltip="Inspeccionar XML" onClick={() => setVisible(true)} />
        </div>
    );
};

export default XMLInspector;
