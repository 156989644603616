import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../../hooks/useHttpClient';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { isEmpty } from 'lodash';

const EmailCambiarClave = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const httpClient = useHttpClient();

    useEffect(() => {
        if (isEmpty(userName)) {
            setIsValid(false);
            return;
        }
        setIsValid(true);
    }, [userName]);

    const sendEmail = () => {
        setIsLoading(true);
        httpClient
            .post('admin/public/usuario/email/cambiarClave', { nombre_usuario: userName })
            .then((response) => {
                setIsLoading(false);
                navigate('/');
            })
            .catch((error) => {
                setIsLoading(false);
                navigate('/');
            });
    };

    return (
        <div className="h-screen flex flex-column bg-cover" style={{ backgroundImage: 'url(/layout/images/pages/login-bg.jpg)' }}>
            <div className="align-self-center mt-auto mb-auto">
                <div className="pages-panel card flex flex-column">
                    <h4>Cambiar clave</h4>

                    <div className="pages-detail mb-6 px-6">Por favor ingrese su nombre de usuario</div>

                    <div className="input-panel flex flex-column px-3">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-envelope"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText type="text" id="inputgroup1" value={userName} onChange={(e) => setUserName(e.target.value)} />
                                <label htmlFor="inputgroup1">Nombre de usuario</label>
                            </span>
                        </div>
                        <div className="flex justify-content-center mb-6"></div>
                    </div>
                    <Button className="mr-2 mb-2" label="Enviar" disabled={!isValid} loading={isLoading} onClick={sendEmail}></Button>
                </div>
            </div>
        </div>
    );
};

export default EmailCambiarClave;
