import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../../hooks/useHttpClient';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { isEmpty } from 'lodash';
import { FloatLabel } from 'primereact/floatlabel';

const CambiarClaveUsuario = () => {
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState('');
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    const navigate = useNavigate();

    const httpClient = useHttpClient();

    useEffect(() => {
        if (!isEmpty(token)) {
            setIsLoading(true);
            httpClient
                .post('/admin/usuario/decode/token', { token: token })
                .then((response) => {
                    setUser(response.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    navigate('/');
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        if (password !== repeatPassword) {
            setIsValid(false);
            return;
        }

        if (isEmpty(password) || isEmpty(repeatPassword)) {
            setIsValid(false);
            return;
        }

        setIsValid(true);
    }, [password, repeatPassword]);

    const updatePassword = () => {
        setIsLoading(true);
        const body = {
            nombre_usuario: user.nombre_usuario,
            nueva_clave: password
        };

        httpClient
            .post('admin/public/usuario/cambiarClave', body)
            .then(() => {
                setIsLoading(false);
                navigate('/');
            })
            .catch(() => {
                setIsLoading(false);
                navigate('/');
            });
    };

    const getFormErrorMessage = () => {
        return !isValid ? <small className="p-error"></small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <div className="h-screen flex flex-column bg-cover" style={{ backgroundImage: 'url(/layout/images/pages/login-bg.jpg)' }}>
            <div className="align-self-center mt-auto mb-auto text-center">
                <div className="pages-panel card flex flex-column" style={{ maxWidth: '300px' }}>
                    <h4>Cambiar clave</h4>

                    <div className="pages-detail mb-6 px-6">Hola, {user.nombre_usuario}. Por favor ingrese su nueva contraseña</div>
                    <div className="input-panel flex flex-column align-items-center">
                        <div className="p-fluid formgrid grid">
                            <div className="field col-12 md:col-12">
                                <FloatLabel>
                                    <Password
                                        id="clave1"
                                        type="text"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className={classNames({ 'p-invalid': !isValid })}
                                        promptLabel="Por favor ingrese una clave"
                                        weakLabel="Débil"
                                        mediumLabel="Medio"
                                        strongLabel="Robusta"
                                        autoComplete="new-password"
                                        toggleMask
                                    />
                                    <label htmlFor="clave1">Clave</label>
                                    {getFormErrorMessage()}
                                </FloatLabel>
                            </div>
                            <div className="field col-12 md:col-12">
                                <FloatLabel>
                                    <Password
                                        id="clave2"
                                        type="text"
                                        value={repeatPassword}
                                        onChange={(e) => setRepeatPassword(e.target.value)}
                                        className={classNames({ 'p-invalid': !isValid })}
                                        promptLabel="Por favor repita la clave clave"
                                        weakLabel="Débil"
                                        mediumLabel="Medio"
                                        strongLabel="Robusta"
                                        autoComplete="new-password"
                                        style={{ width: '100%' }}
                                        toggleMask
                                    />
                                    <label htmlFor="clave2">Repita la clave</label>
                                    {getFormErrorMessage()}
                                </FloatLabel>
                            </div>
                        </div>

                        {/*<div className="p-fluid formgrid grid"></div>*/}
                    </div>
                    <Button className="mr-2 mb-2" disabled={!isValid} loading={isLoading} onClick={() => updatePassword()} label="Guardar" style={{ marginTop: '20px' }} />
                </div>
            </div>
        </div>
    );
};

export default CambiarClaveUsuario;
