import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../hooks/useHttpClient';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { isEmptyArray } from 'formik';

const SeleccionarPais = (props) => {
    const httpClient = useHttpClient();
    const [paises, setPaises] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isEmptyArray(paises)) {
            setIsLoading(true);
            httpClient
                .get(`api/paises`)
                .then((response) => {
                    setPaises(response.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paises]);

    const getSelect = () => {
        if (isLoading) {
            return <Skeleton id={props.id} height={props.skeletonHeigh} width={props.skeletonWidth} className="mb-2"></Skeleton>;
        }
        return (
            <Dropdown
                id={props.id}
                value={props.value}
                filter
                onChange={(e) => props.onChange(e)}
                onBlur={() => props.onBlur()}
                options={paises}
                optionLabel="nombre"
                placeholder="Seleccionar pais"
                className={props.className}
                virtualScrollerOptions={{ itemSize: 38 }}
            />
        );
    };

    return getSelect();
};

export default SeleccionarPais;
