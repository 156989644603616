import { isEmpty } from 'lodash';

export const convertItemsToEdocFormat = (items) => {
    if (isEmpty(items)) {
        return [];
    }
    const retItems = [];

    items.forEach((item) => {
        retItems.push({
            ...item,
            c_uni_med: item.unidad_medida?.c_uni_med || 77,
            d_des_uni_med: item.unidad_medida?.d_des_uni_med || 'UNI',
            g_cam_iva: {
                d_des_afec_iva: item.g_cam_iva?.afectacion_tributaria?.d_des_afec_iva,
                i_afec_iva: item.g_cam_iva?.afectacion_tributaria?.i_afec_iva,
                d_prop_iva: item.g_cam_iva?.d_prop_iva,
                d_tasa_iva: item.g_cam_iva?.d_tasa_iva
            },
            g_valor_item: {
                d_p_uni_pro_ser: item.g_valor_item?.d_p_uni_pro_ser,
                d_tot_bru_ope_item: item.g_valor_item?.d_tot_bru_ope_item,
                g_valor_resta_item: {
                    d_desc_item: item.g_valor_item?.g_valor_resta_item?.d_desc_item,
                    d_porc_des_it: item.g_valor_item?.g_valor_resta_item?.d_porc_des_it,
                    d_desc_glo_item: item.g_valor_item?.g_valor_resta_item?.d_desc_glo_item,
                    d_ant_pre_uni_lt: item.g_valor_item?.g_valor_resta_item?.d_ant_pre_uni_lt,
                    d_ant_glo_pre_unit_lt: item.g_valor_item?.g_valor_resta_item?.d_ant_glo_pre_unit_lt,
                    d_tot_ope_item: item.g_valor_item?.g_valor_resta_item?.d_tot_ope_item,
                    d_tot_ope_gs: item.g_valor_item?.g_valor_resta_item?.d_tot_ope_gs
                }
            }
        });
    });
    return retItems;
};

export const convertItemsToFormikFormat = (items) => {
    if (isEmpty(items)) {
        return [];
    }

    const retItems = [];
    items.forEach((item) => {
        let newItem = {
            ...item,
            unidad_medida: {
                c_uni_med: item.c_uni_med,
                d_des_uni_med: item.d_des_uni_med
            }
        };
        newItem.g_cam_iva.afectacion_tributaria = {
            i_afec_iva: item.g_cam_iva?.i_afec_iva,
            d_des_afec_iva: item.g_cam_iva?.d_des_afec_iva
        };
        retItems.push(newItem);
    });
    return retItems;
};
