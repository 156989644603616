export const NATURALEZA_RECEPTOR = [
    {
        i_nat_rec: 1,
        d_des_nat_rec: 'Contribuyente'
    },
    {
        i_nat_rec: 2,
        d_des_nat_rec: 'No contribuyente'
    }
];

export const TIPO_OPERACION_RECEPTOR = [
    {
        i_ti_ope: 1,
        d_des_ti_ope: 'B2B'
    },
    {
        i_ti_ope: 2,
        d_des_ti_ope: 'B2C'
    },
    {
        i_ti_ope: 3,
        d_des_ti_ope: 'B2G'
    },
    {
        i_ti_ope: 4,
        d_des_ti_ope: 'B2F'
    }
];

export const TIPO_CONTRIBUYENTE_RECEPTOR = [
    {
        i_ti_con_rec: 1,
        d_des_ti_con_rec: 'Persona Física'
    },
    {
        i_ti_con_rec: 2,
        d_des_ti_con_rec: 'Persona Jurídica'
    }
];

export const TIPO_DOC_RECEPTOR = [
    {
        i_tip_id_rec: 1,
        d_d_tip_id_rec: 'Cédula paraguaya'
    },
    {
        i_tip_id_rec: 2,
        d_d_tip_id_rec: 'Pasaporte'
    },
    {
        i_tip_id_rec: 3,
        d_d_tip_id_rec: 'Cédula extranjera'
    },
    {
        i_tip_id_rec: 4,
        d_d_tip_id_rec: 'Carnet de residencia'
    },
    {
        i_tip_id_rec: 5,
        d_d_tip_id_rec: 'Innominado'
    },
    {
        i_tip_id_rec: 6,
        d_d_tip_id_rec: 'Tarjeta Diplomática de exoneración fiscal'
    },
    {
        i_tip_id_rec: 9,
        d_d_tip_id_rec: 'Otro'
    }
];
