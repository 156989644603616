import { addLocale } from 'primereact/api';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import { CONDICION_NEGOCIACION, MODALIDA_TRANSPORTE, RESPONSABLE_COSTO_FLETE, TIPO_TRANSPORTE } from '../constants/transporte_mercaderias';

const GTransp = (props) => {
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    return (
        <>
            <div className="p-fluid formgrid grid">
                <Divider align="center">
                    <b>Campos que describen el transporte de mercaderías</b>
                </Divider>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <Dropdown
                            id="tipoTransporte"
                            value={props.formik.values.tipo_transporte}
                            onChange={(e) => props.formik.setFieldValue('tipo_transporte', e.value)}
                            onBlur={() => props.formik.setFieldTouched('tipo_transporte', true)}
                            options={TIPO_TRANSPORTE}
                            optionLabel="d_des_tip_trans"
                            placeholder="Seleccione el tipo de transporte"
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('tipo_transporte') })}
                        />
                        <label htmlFor="tipoTransporte">Tipo de transporte</label>
                        {props.getFormErrorMessage('tipo_transporte')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <Dropdown
                            id="modTrans"
                            value={props.formik.values.modalidad_transporte}
                            onChange={(e) => props.formik.setFieldValue('modalidad_transporte', e.value)}
                            onBlur={() => props.formik.setFieldTouched('modalidad_transporte', true)}
                            options={MODALIDA_TRANSPORTE}
                            optionLabel="d_des_mod_trans"
                            placeholder="Seleccione la modalidad del transporte"
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('modalidad_transporte') })}
                        />
                        <label htmlFor="modTrans">Modalidad del transporte</label>
                        {props.getFormErrorMessage('modalidad_transporte')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <Dropdown
                            id="respFlete"
                            value={props.formik.values.responsable_flete}
                            onChange={(e) => props.formik.setFieldValue('responsable_flete', e.value)}
                            onBlur={() => props.formik.setFieldTouched('responsable_flete', true)}
                            options={RESPONSABLE_COSTO_FLETE}
                            optionLabel="d_des_resp_flete"
                            placeholder="Seleccione al responsable del costo del flete"
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('responsable_flete') })}
                        />
                        <label htmlFor="respFlete">Responsable del costo del flete</label>
                        {props.getFormErrorMessage('responsable_flete')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <Dropdown
                            id="condNegociacion"
                            value={props.formik.values.condicion_negociacion}
                            onChange={(e) => props.formik.setFieldValue('condicion_negociacion', e.value)}
                            onBlur={() => props.formik.setFieldTouched('condicion_negociacion', true)}
                            options={CONDICION_NEGOCIACION}
                            optionLabel="d_des_cond_neg"
                            placeholder="Seleccione la condición de la negociación"
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('condicion_negociacion') })}
                        />
                        <label htmlFor="condNegociacion">Condición de la negociación</label>
                        {props.getFormErrorMessage('condicion_negociacion')}
                    </FloatLabel>
                </div>
                {props.formik.values.mot_emision_nr?.i_mot_emi_nr === 5 && (
                    <div className="field col-12 md:col-2">
                        <FloatLabel>
                            <InputText
                                id="nroDespacho"
                                value={props.formik.values.d_nu_desp_imp}
                                onChange={(e) => props.formik.setFieldValue('d_nu_desp_imp', e.target.value)}
                                onBlur={() => props.formik.setFieldTouched('d_nu_desp_imp', true)}
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_nu_desp_imp') })}
                            />
                            <label htmlFor="nroDespacho">Nro. Despacho de importación</label>
                            {props.getFormErrorMessage('d_nu_desp_imp')}
                        </FloatLabel>
                    </div>
                )}
                <div className="field col-12 md:col-3">
                    <FloatLabel>
                        <Calendar
                            id="fechaInicioTransporte"
                            value={props.formik.values.d_ini_tras}
                            onChange={(e) => props.formik.setFieldValue('d_ini_tras', e.target.value)}
                            onFocus={() => props.formik.setFieldTouched('d_ini_tras', true)}
                            dateFormat="yy-mm-dd"
                            locale="es"
                            selectionMode={'single'}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_ini_tras') })}
                        />
                        <label htmlFor="fechaInicioTransporte">Fecha estimada de inicio de traslado</label>
                        {props.getFormErrorMessage('d_ini_tras')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-3">
                    <FloatLabel>
                        <Calendar
                            id="fechaFinTransporte"
                            value={props.formik.values.d_fin_tras}
                            onChange={(e) => props.formik.setFieldValue('d_fin_tras', e.target.value)}
                            onFocus={() => props.formik.setFieldTouched('d_fin_tras', true)}
                            dateFormat="yy-mm-dd"
                            locale="es"
                            selectionMode={'single'}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_fin_tras') })}
                        />
                        <label htmlFor="fechaFinTransporte">Fecha estimada de fin de traslado</label>
                        {props.getFormErrorMessage('d_fin_tras')}
                    </FloatLabel>
                </div>
            </div>
        </>
    );
};
export default GTransp;
