import React, { useEffect, useMemo, useState } from 'react';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import SeleccionarPais from '../../../components/SeleccionarPais';
import BuscarRUC from '../../../components/BuscarRUC';
import { InputText } from 'primereact/inputtext';
import { isEmpty } from 'lodash';
import SeleccionarDepartamento from '../../../components/SeleccionarDepartamento';
import SeleccionarDistrito from '../../../components/SeleccionarDistrito';
import SeleccionarLocalidad from '../../../components/SeleccionarLocalidad';
import { setLocale } from 'yup';
import { es } from 'yup-locales';
import { FloatLabel } from 'primereact/floatlabel';
import { ToggleButton } from 'primereact/togglebutton';
import { NATURALEZA_RECEPTOR, TIPO_CONTRIBUYENTE_RECEPTOR, TIPO_DOC_RECEPTOR } from './constants/receptor';

const GDatRec = (props) => {
    const [esInstitucionPublica, setEsInstitucionPublica] = useState(false);
    setLocale(es);

    const onRUCFound = (value) => {
        props.formik.setFieldValue('d_nom_rec', value);
    };

    const onRUCChange = (value) => {
        props.formik.setFieldValue('d_ruc_rec', value);
    };

    const paisSeleccionado = useMemo(() => {
        return props.formik.values.pais_rec;
    }, [props.formik.values.pais_rec]);

    useEffect(() => {
        //Si el receptor es un contribuyente, entonces el tipo de operación debe ser B2B y el país Paraguay
        if (props.formik.values.nat_rec?.i_nat_rec === 1) {
            props.formik.setFieldValue('pais_rec', { codigo: 'PRY', nombre: 'Paraguay' });

            if (props.formik.values.tipo_cont_rec?.i_ti_con_rec === 2) {
                //Si el tipo de contribuyente receptor es Persona jurídica y es una institución pública,
                // entonces el tipo de operación debe ser B2G, si no B2B
                if (esInstitucionPublica) {
                    props.formik.setFieldValue('tipo_op_rec', { i_ti_ope: 3, d_des_ti_ope: 'B2G' });
                } else {
                    props.formik.setFieldValue('tipo_op_rec', { i_ti_ope: 1, d_des_ti_ope: 'B2B' });
                }
            }

            if (props.formik.values.tipo_cont_rec?.i_ti_con_rec === 1) {
                //Si el tipo de contribuyente receptor es Persona física, entonces el tipo de operación debe ser B2B
                props.formik.setFieldValue('tipo_op_rec', { i_ti_ope: 1, d_des_ti_ope: 'B2B' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formik.values.nat_rec, props.formik.values.tipo_cont_rec?.i_ti_con_rec, esInstitucionPublica]);

    useEffect(() => {
        if (props.formik.values.nat_rec?.i_nat_rec === 2) {
            //Si el receptor es no contribuyente y el país seleccionado es Paraguay,
            // entonces el tipo de operación debe ser B2C
            if (paisSeleccionado?.codigo === 'PRY') {
                props.formik.setFieldValue('tipo_op_rec', { i_ti_ope: 2, d_des_ti_ope: 'B2C' });
            }

            //Si el receptor es no contribuyente y el país seleccionado es diferente a Paraguay,
            // entonces el tipo de operación debe ser B2F
            if (paisSeleccionado?.codigo !== 'PRY') {
                props.formik.setFieldValue('tipo_op_rec', { i_ti_ope: 4, d_des_ti_ope: 'B2F' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paisSeleccionado, props.formik.values.nat_rec?.i_nat_rec]);

    return (
        <div className="p-fluid formgrid grid">
            <Divider align="center">
                <b>Campos que identifican al receptor del documento electrónico</b>
            </Divider>

            <div className="field col-12 md:col-4">
                <FloatLabel>
                    <Dropdown
                        id="natReceptor"
                        value={props.formik.values.nat_rec}
                        onChange={(e) => props.formik.setFieldValue('nat_rec', e.value)}
                        onBlur={() => props.formik.setFieldTouched('nat_rec', true)}
                        options={NATURALEZA_RECEPTOR}
                        optionLabel="d_des_nat_rec"
                        placeholder="Seleccione la naturaleza del receptor"
                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('nat_rec') })}
                    />
                    <label htmlFor="natReceptor">Naturaleza del receptor</label>
                    {props.getFormErrorMessage('nat_rec')}
                </FloatLabel>
            </div>

            {props.formik.values.nat_rec?.i_nat_rec === 2 && (
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <SeleccionarPais
                            id="paisRec"
                            value={props.formik.values.pais_rec}
                            onChange={(e) => props.formik.setFieldValue('pais_rec', e.value)}
                            onBlur={() => props.formik.setFieldTouched('pais_rec', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('pais_rec') })}
                        />
                        <label htmlFor="paisRec">País</label>
                        {props.getFormErrorMessage('pais_rec')}
                    </FloatLabel>
                </div>
            )}

            {props.formik.values.nat_rec?.i_nat_rec === 1 && (
                <>
                    <div className="field col-12 md:col-4">
                        <FloatLabel>
                            <Dropdown
                                id="tipoContRec"
                                value={props.formik.values.tipo_cont_rec}
                                onChange={(e) => props.formik.setFieldValue('tipo_cont_rec', e.value)}
                                onBlur={() => props.formik.setFieldTouched('tipo_cont_rec', true)}
                                options={TIPO_CONTRIBUYENTE_RECEPTOR}
                                optionLabel="d_des_ti_con_rec"
                                placeholder="Seleccione la naturaleza del receptor"
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('tipo_cont_rec') })}
                            />
                            <label htmlFor="tipoContRec">Tipo de contribuyente receptor</label>
                            {props.getFormErrorMessage('tipo_cont_rec')}
                        </FloatLabel>
                    </div>
                    {props.formik.values.tipo_cont_rec?.i_ti_con_rec === 2 && (
                        <div className="field col-12 md:col-2">
                            <label htmlFor="institucionPublica">¿Es una institución pública?</label>
                            <ToggleButton
                                checked={esInstitucionPublica}
                                onLabel="Sí"
                                offLabel="No"
                                onChange={(e) => {
                                    setEsInstitucionPublica(e.value);
                                }}
                            />
                        </div>
                    )}
                    <div className="field col-12 md:col-4">
                        <FloatLabel>
                            <BuscarRUC
                                id="rucRec"
                                value={props.formik.values.d_ruc_rec}
                                onSuccess={onRUCFound}
                                onChange={onRUCChange}
                                onNotFound={props.onRUCNotFound}
                                onError={props.onError}
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_ruc_rec') })}
                            />
                            <label htmlFor="rucRec">RUC</label>
                            {props.getFormErrorMessage('d_ruc_rec')}
                        </FloatLabel>
                    </div>
                </>
            )}
            {props.formik.values.nat_rec?.i_nat_rec === 2 && (
                <React.Fragment>
                    <div className="field col-12 md:col-4">
                        <FloatLabel>
                            <Dropdown
                                id="tipoDocRec"
                                value={props.formik.values.tipo_doc_rec}
                                onChange={(e) => props.formik.setFieldValue('tipo_doc_rec', e.value)}
                                onBlur={() => props.formik.setFieldTouched('tipo_doc_rec', true)}
                                options={TIPO_DOC_RECEPTOR}
                                optionLabel="d_d_tip_id_rec"
                                placeholder="Seleccione la naturaleza del receptor"
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('tipo_doc_rec') })}
                            />
                            <label htmlFor="tipoDocRec">Tipo de documento de identidad</label>
                            {props.getFormErrorMessage('tipo_doc_rec')}
                        </FloatLabel>
                    </div>

                    {props.formik.values.tipo_doc_rec?.i_tip_id_rec === 9 && (
                        <div className="field col-12 md:col-4">
                            <FloatLabel>
                                <InputText
                                    id="descTipoDoc"
                                    type="text"
                                    value={props.formik.values.desc_tipo_doc}
                                    onChange={(e) => props.formik.setFieldValue('desc_tipo_doc', e.target.value)}
                                    onBlur={() => props.formik.setFieldTouched('desc_tipo_doc', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('desc_tipo_doc') })}
                                />
                                <label htmlFor="descTipoDoc">Descripción del tipo de documento de identidad</label>
                                {props.getFormErrorMessage('desc_tipo_doc')}
                            </FloatLabel>
                        </div>
                    )}
                    <div className="field col-12 md:col-4">
                        <FloatLabel>
                            <InputText
                                id="nroDocumentoRec"
                                type="text"
                                value={props.formik.values.d_num_id_rec}
                                onChange={(e) => props.formik.setFieldValue('d_num_id_rec', e.target.value)}
                                onBlur={() => props.formik.setFieldTouched('d_num_id_rec', true)}
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_num_id_rec') })}
                            />
                            <label htmlFor="nroDocumentoRec">Nro. de documento</label>
                            {props.getFormErrorMessage('d_num_id_rec')}
                        </FloatLabel>
                    </div>
                </React.Fragment>
            )}

            <div className="field col-12 md:col-4">
                <FloatLabel>
                    <InputText
                        id="razonSocial"
                        type="text"
                        value={props.formik.values.d_nom_rec}
                        onChange={(e) => props.formik.setFieldValue('d_nom_rec', e.target.value)}
                        onBlur={() => props.formik.setFieldTouched('d_nom_rec', true)}
                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_nom_rec') })}
                    />
                    <label htmlFor="razonSocial">Razón social</label>
                    {props.getFormErrorMessage('d_nom_rec')}
                </FloatLabel>
            </div>

            <div className="field col-12 md:col-8">
                <FloatLabel>
                    <InputText
                        id="dirRec"
                        type="text"
                        value={props.formik.values.d_dir_rec}
                        onChange={(e) => props.formik.setFieldValue('d_dir_rec', e.target.value)}
                        onBlur={() => props.formik.setFieldTouched('d_dir_rec', true)}
                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_dir_rec') })}
                    />
                    <label htmlFor="dirRec">Dirección</label>
                    {props.getFormErrorMessage('d_dir_rec')}
                </FloatLabel>
            </div>

            {!isEmpty(props.formik.values?.d_dir_rec) && (
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <InputText
                            id="nroCasaRec"
                            value={props.formik.values.d_num_cas_rec}
                            onChange={(e) => props.formik.setFieldValue('d_num_cas_rec', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('d_num_cas_rec', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_num_cas_rec') })}
                        />
                        <label htmlFor="nroCasaRec">Nro. de casa</label>
                        {props.getFormErrorMessage('d_num_cas_rec')}
                    </FloatLabel>
                </div>
            )}

            {!isEmpty(props.formik.values.d_dir_rec) && props.formik.values.tipo_op_rec?.i_ti_ope !== 4 && (
                <>
                    <div className="field col-12 md:col-4">
                        <FloatLabel>
                            <SeleccionarDepartamento
                                id="dptoRect"
                                value={props.formik.values.departamento_rec}
                                onChange={(e) => props.formik.setFieldValue('departamento_rec', e.target.value)}
                                onBlur={() => props.formik.setFieldTouched('departamento_rec', true)}
                                skeletonHeigh={'3rem'}
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('departamento_rec') })}
                            />
                            <label htmlFor="dptoRect">Departamento</label>
                            {props.getFormErrorMessage('departamento_rec')}
                        </FloatLabel>
                    </div>
                    <div className="field col-12 md:col-4">
                        <FloatLabel>
                            <SeleccionarDistrito
                                id="distRect"
                                departamento={props.formik.values.departamento_rec?.codigo}
                                value={props.formik.values.distrito_rec}
                                onChange={(e) => props.formik.setFieldValue('distrito_rec', e.target.value)}
                                onBlur={() => props.formik.setFieldTouched('distrito_rec', true)}
                                skeletonHeigh={'3rem'}
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('distrito_rec') })}
                            />
                            <label htmlFor="distRect">Distrito</label>
                            {props.getFormErrorMessage('distrito_rec')}
                        </FloatLabel>
                    </div>
                    <div className="field col-12 md:col-4">
                        <FloatLabel>
                            <SeleccionarLocalidad
                                id="localidadRec"
                                distrito={props.formik.values.distrito_rec?.codigo}
                                value={props.formik.values.localidad_rec}
                                onChange={(e) => props.formik.setFieldValue('localidad_rec', e.target.value)}
                                onBlur={() => props.formik.setFieldTouched('localidad_rec', true)}
                                skeletonHeigh={'3rem'}
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('localidad_rec') })}
                            />
                            <label htmlFor="localidadRec">Localidad</label>
                            {props.getFormErrorMessage('localidad_rec')}
                        </FloatLabel>
                    </div>
                </>
            )}

            <div className="field col-12 md:col-4">
                <FloatLabel>
                    <InputText
                        id="nroTelRec"
                        type="text"
                        value={props.formik.values.d_tel_rec}
                        onChange={(e) => props.formik.setFieldValue('d_tel_rec', e.target.value)}
                        onBlur={() => props.formik.setFieldTouched('d_tel_rec', true)}
                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_tel_rec') })}
                    />
                    <label htmlFor="nroTelRec">Nro. Teléfono</label>
                    {props.getFormErrorMessage('d_tel_rec')}
                </FloatLabel>
            </div>
            <div className="field col-12 md:col-4">
                <FloatLabel>
                    <InputText
                        id="nroCelRec"
                        type="text"
                        value={props.formik.values.d_cel_rec}
                        onChange={(e) => props.formik.setFieldValue('d_cel_rec', e.target.value)}
                        onBlur={() => props.formik.setFieldTouched('d_cel_rec', true)}
                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_cel_rec') })}
                    />
                    <label htmlFor="nroCelRec">Nro. Celular</label>
                    {props.getFormErrorMessage('d_cel_rec')}
                </FloatLabel>
            </div>
            <div className="field col-12 md:col-4">
                <FloatLabel>
                    <InputText
                        id="emailRec"
                        type="text"
                        value={props.formik.values.d_email_rec}
                        onChange={(e) => props.formik.setFieldValue('d_email_rec', e.target.value)}
                        onBlur={() => props.formik.setFieldTouched('d_email_rec', true)}
                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_email_rec') })}
                    />
                    <label htmlFor="emailRec">E-mail</label>
                    {props.getFormErrorMessage('d_email_rec')}
                </FloatLabel>
            </div>
        </div>
    );
};

export default GDatRec;
