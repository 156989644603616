import { configureStore } from '@reduxjs/toolkit';

import { sessionReducer } from './pages/Login/reducer';
import { edocReducer } from './pages/DocumentosElectronicos/reducer';
import { dashboardReducer } from './pages/Dashboard/reducer';

export default configureStore({
    reducer: {
        session: sessionReducer.reducer,
        edoc: edocReducer.reducer,
        dashboard: dashboardReducer.reducer
    }
});
