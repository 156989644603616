import React, { useEffect, useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';
import { isEmpty } from 'lodash';
import { useHttpClient } from '../hooks/useHttpClient';
import { BlockUI } from 'primereact/blockui';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { FloatLabel } from 'primereact/floatlabel';

const ActualizarCertficado = (props) => {
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isBlocked, setIsBlocket] = useState(false);
    const httpClient = useHttpClient();
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);

    useEffect(() => {
        if (password !== repeatPassword) {
            setIsValid(false);
            return;
        }

        if (isEmpty(password) || isEmpty(repeatPassword)) {
            setIsValid(false);
            return;
        }

        setIsValid(true);
    }, [password, repeatPassword]);

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };
    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-file mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Puede arrastrar y soltar su certificado aquí
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-file', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    const uploadCert = async (event) => {
        const file = event.files[0];

        const form = new FormData();
        form.append('clave', password);
        form.append('cert', file);

        setIsBlocket(true);

        const url = props.url ? props.url : 'admin/emisor/actualizar/cert/';

        httpClient
            .put(url + props.ruc, form)
            .then((response) => {
                setIsBlocket(false);
                props.onSuccess();
            })
            .catch((error) => {
                setIsBlocket(false);
                props.onError(error);
            });
    };

    const getFormErrorMessage = () => {
        return !isValid ? <small className="p-error">Clave inválida</small> : <small className="p-error">&nbsp;</small>;
    };
    return (
        <BlockUI blocked={isBlocked}>
            <div className="p-fluid formgrid grid">
                {/*<div className="card">*/}
                <div className="field col-12 md:col-12">
                    <FloatLabel>
                        <Password
                            id="password"
                            type="text"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={classNames({ 'p-invalid': !isValid })}
                            promptLabel="Por favor ingrese una clave"
                            weakLabel="Débil"
                            mediumLabel="Medio"
                            strongLabel="Robusta"
                            autoComplete="new-password"
                            toggleMask
                        />
                        <label htmlFor="password">Clave</label>
                        {getFormErrorMessage()}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-12">
                    <FloatLabel>
                        <Password
                            id="repeatPassword"
                            type="text"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            className={classNames({ 'p-invalid': !isValid })}
                            weakLabel="Débil"
                            mediumLabel="Medio"
                            strongLabel="Robusta"
                            autoComplete="new-password"
                            toggleMask
                        />
                        <label htmlFor="repeatPassword">Repita la clave</label>
                        {getFormErrorMessage()}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-12">
                    <Tooltip target=".custom-choose-btn" content="Seleccionar" position="bottom" />
                    <Tooltip target=".custom-upload-btn" content="Enviar" position="bottom" />
                    <Tooltip target=".custom-cancel-btn" content="Limpiar" position="bottom" />

                    <FileUpload
                        name="certUpload"
                        customUpload
                        ref={fileUploadRef}
                        uploadHandler={uploadCert}
                        onUpload={onTemplateUpload}
                        onSelect={onTemplateSelect}
                        onError={onTemplateClear}
                        onClear={onTemplateClear}
                        headerTemplate={headerTemplate}
                        itemTemplate={itemTemplate}
                        emptyTemplate={emptyTemplate}
                        chooseOptions={chooseOptions}
                        uploadOptions={uploadOptions}
                        cancelOptions={cancelOptions}
                        accept="application/x-pkcs12"
                    />
                </div>
                {/*</div>*/}
            </div>
        </BlockUI>
    );
};

export default ActualizarCertficado;
