import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { isEmpty } from 'lodash';
import { InputTextarea } from 'primereact/inputtextarea';

const ActividadEconomica = (props) => {
    const [displayDialog, setDisplayDialog] = useState(false);
    const [codigo, setCodigo] = useState('');
    const [descripcion, setDescripcion] = useState('');

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const header = () => {
        return (
            <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                <span className="text-xl text-900 font-bold"></span>
                {props.value?.length < 9 && <Button type="button" icon="pi pi-plus" onClick={() => setDisplayDialog(true)} />}
            </div>
        );
    };

    const guardarActividadEconomica = () => {
        const arrCopy = [...props.value];
        arrCopy.push({
            codigo: codigo,
            descripcion: descripcion
        });
        props.onSave(arrCopy);
        setCodigo('');
        setDescripcion('');
        setDisplayDialog(false);
    };

    const onRowEditComplete = (e) => {
        const idx = props.value.findIndex((el) => {
            return el.codigo === e.newData.codigo;
        });
        if (idx >= 0) {
            const arrCopy = [...props.value];
            arrCopy[idx] = e.newData;
            props.onSave(arrCopy);
        }
    };

    const eliminarItem = (item) => {
        const idx = props.value.findIndex((el) => {
            return el.codigo === item.codigo;
        });

        if (idx >= 0) {
            const arrCopy = [...props.value];
            arrCopy.splice(idx, 1);
            props.onSave(arrCopy);
        }
    };

    const actionTemplate = (rowData) => {
        return <Button id="eliminar" type="button" icon="pi pi-times" severity="danger" onClick={() => eliminarItem(rowData)} />;
    };

    return (
        <>
            <DataTable value={props.value} key="codigo" header={header} className="mt-3" filterDisplay="row" editMode="row" stripedRows responsiveLayout="stack" emptyMessage="No existen actividades económicas" onRowEditComplete={onRowEditComplete}>
                <Column field="codigo" header="Código" sortable editor={(options) => textEditor(options)} style={{ maxWidth: '25%' }} />
                <Column field="descripcion" header="Descripción" editor={(options) => textEditor(options)} sortable style={{ minWidth: '75%' }} />
                <Column body={actionTemplate} style={{ maxWidth: '1%' }}></Column>
                <Column rowEditor={true} style={{ maxWidth: '1%' }} bodyStyle={{ textAlign: 'center' }}></Column>
            </DataTable>
            <Dialog visible={displayDialog} header="Agregar actividad económica" modal style={{ maxWidth: '60vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} onHide={() => setDisplayDialog(false)}>
                <div className="grid">
                    <div className="col-12">
                        <div className="p-fluid formgrid grid">
                            <div className="col-12 md:col-12" style={{ marginTop: '0.5rem' }}>
                                <span className="p-float-label">
                                    <InputText id="actCodigo" type="text" value={codigo} onChange={(e) => setCodigo(e.target.value)} className={classNames({ 'p-invalid': isEmpty(codigo) })} />
                                    <label htmlFor="actCodigo">Codigo</label>

                                    {isEmpty(codigo) && <small className="p-error">Campo requerido</small>}
                                </span>
                            </div>
                            <div className="field col-12 md:col-12" style={{ marginTop: '0.5rem' }}>
                                <span className="p-float-label">
                                    <InputTextarea id="actDescripcion" type="text" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} className={classNames({ 'p-invalid': isEmpty(descripcion) })} />
                                    <label htmlFor="actDescripcion">Descripción</label>
                                    {isEmpty(descripcion) && <small className="p-error">Campo requerido</small>}
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-content-end gap-2">
                            <Button type="button" className=" p-button-danger field col-12 md:col-2" onClick={() => setDisplayDialog(false)} label="Cancelar" />
                            <Button type="button" className="field col-12 md:col-2" disabled={isEmpty(codigo) || isEmpty(descripcion)} onClick={() => guardarActividadEconomica()} label="Guardar" />
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ActividadEconomica;
