import { Divider } from 'primereact/divider';
import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';
import { addLocale } from 'primereact/api';
import { isEmpty } from 'lodash';
import ImpuestoAfectadoRG90 from '../../../components/ImpuestoAfectadoRG90';
import { FloatLabel } from 'primereact/floatlabel';
import { Calendar } from 'primereact/calendar';

const GOpeCom = (props) => {
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    const listaTipoTransaccion = [
        {
            i_tip_tra: 1,
            d_des_trip_tra: 'Venta de mercadería'
        },
        {
            i_tip_tra: 2,
            d_des_trip_tra: 'Prestación de servicios'
        },
        {
            i_tip_tra: 3,
            d_des_trip_tra: 'Mixto (Venta de mercadería y servicios)'
        },
        {
            i_tip_tra: 4,
            d_des_trip_tra: 'Venta de activo fijo'
        },
        {
            i_tip_tra: 5,
            d_des_trip_tra: 'Venta de divisas'
        },
        {
            i_tip_tra: 6,
            d_des_trip_tra: 'Compra de divisas'
        },
        {
            i_tip_tra: 7,
            d_des_trip_tra: 'Promoción o entrega de muestras'
        },
        {
            i_tip_tra: 8,
            d_des_trip_tra: 'Donación'
        },
        {
            i_tip_tra: 9,
            d_des_trip_tra: 'Anticipo'
        },
        {
            i_tip_tra: 10,
            d_des_trip_tra: 'Compra de productos'
        },
        {
            i_tip_tra: 11,
            d_des_trip_tra: 'Compra de servicios'
        },
        {
            i_tip_tra: 12,
            d_des_trip_tra: 'Venta de crédito fiscal'
        },
        {
            i_tip_tra: 13,
            d_des_trip_tra: 'Muestras médicas (Art. 3 RG 24/2014)'
        }
    ];

    const listaDescImpuestoAfectado = [
        {
            i_timp: 1,
            d_des_timp: 'IVA'
        },
        {
            i_timp: 2,
            d_des_timp: 'ISC'
        },
        {
            i_timp: 3,
            d_des_timp: 'Renta'
        },
        {
            i_timp: 4,
            d_des_timp: 'Ninguno'
        },
        {
            i_timp: 5,
            d_des_timp: 'IVA - Renta'
        }
    ];

    const listaMonedas = [
        {
            c_mone_ope: 'PYG',
            d_des_mone_ope: 'Guarani'
        },
        {
            c_mone_ope: 'USD',
            d_des_mone_ope: 'US Dollar'
        }
    ];

    const listaTipoCambioOp = [
        {
            d_cond_ti_cam: 1,
            d_des_cond_ti_cam: 'Global'
        }
        // {
        //     d_cond_ti_cam: 2,
        //     d_des_cond_ti_cam: 'Por ítem'
        // }
    ];

    return (props.formik.values.tipo_de?.i_ti_de !== 7 && (
        <div className="p-fluid formgrid grid">
            <Divider align="center">
                <b>Campos inherentes a la operación comercial</b>
            </Divider>

            {!props.isEditing && (
                <div className="field col-12 md:col-3">
                    <FloatLabel>
                        <Calendar
                            id="dFecEm"
                            value={props.formik.values.d_fe_emi_de}
                            onChange={(e) => props.formik.setFieldValue('d_fe_emi_de', e.target.value)}
                            onFocus={() => props.formik.setFieldTouched('d_fe_emi_de', true)}
                            dateFormat="yy-mm-dd"
                            showTime
                            locale="es"
                            selectionMode={'single'}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_fe_emi_de') })}
                        />
                        <label htmlFor="dFecEm">Fecha de emisión</label>
                        {props.getFormErrorMessage('d_fe_emi_de')}
                    </FloatLabel>
                </div>
            )}

            {(props.formik.values.tipo_de?.i_ti_de === 1 || props.formik.values.tipo_de?.i_ti_de === 4) && (
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <Dropdown
                            id="tipoTransaccion"
                            value={props.formik.values.tipo_trans}
                            onChange={(e) => props.formik.setFieldValue('tipo_trans', e.value)}
                            onBlur={() => props.formik.setFieldTouched('tipo_trans', true)}
                            options={listaTipoTransaccion}
                            optionLabel="d_des_trip_tra"
                            placeholder="Seleccione el tipo de transacción"
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('tipo_trans') })}
                        />
                        <label htmlFor="tipoTransaccion">Tipo de Transacción</label>
                        {props.getFormErrorMessage('tipo_trans')}
                    </FloatLabel>
                </div>
            )}
            <div className="field col-12 md:col-4">
                <FloatLabel>
                    <Dropdown
                        id="tipoImpuestoAfectado"
                        value={props.formik.values.tipo_imp_afectado}
                        onChange={(e) => props.formik.setFieldValue('tipo_imp_afectado', e.value)}
                        onBlur={() => props.formik.setFieldTouched('tipo_imp_afectado', true)}
                        options={listaDescImpuestoAfectado}
                        optionLabel="d_des_timp"
                        placeholder="Seleccione el tipo de impuesto afecto"
                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('tipo_imp_afectado') })}
                    />
                    <label htmlFor="tipoImpuestoAfectado">Tipo de impuesto afectado</label>
                    {props.getFormErrorMessage('tipo_imp_afectado')}
                </FloatLabel>
            </div>

            <div className="field col-12 md:col-4">
                <FloatLabel>
                    <Dropdown
                        id="monedaOperacion"
                        value={props.formik.values.moneda_operacion}
                        onChange={(e) => props.formik.setFieldValue('moneda_operacion', e.value)}
                        onBlur={() => props.formik.setFieldTouched('moneda_operacion', true)}
                        options={listaMonedas}
                        optionLabel="c_mone_ope"
                        placeholder="Seleccione la moneda de la operación"
                        className={classNames({ 'p-invalid': props.isFormFieldInvalid('moneda_operacion') })}
                    />
                    <label htmlFor="monedaOperacion">Moneda de la operación</label>
                    {props.getFormErrorMessage('moneda_operacion')}
                </FloatLabel>
            </div>

            {!isEmpty(props.formik.values.moneda_operacion?.c_mone_ope) && props.formik.values.moneda_operacion?.c_mone_ope !== 'PYG' && (
                <React.Fragment>
                    <div className="field col-12 md:col-4">
                        <FloatLabel>
                            <Dropdown
                                id="condTipoCambio"
                                value={props.formik.values.condicion_tipo_cambio}
                                onChange={(e) => props.formik.setFieldValue('condicion_tipo_cambio', e.value)}
                                onBlur={() => props.formik.setFieldTouched('condicion_tipo_cambio', true)}
                                options={listaTipoCambioOp}
                                optionLabel="d_des_cond_ti_cam"
                                placeholder="Seleccione el tipo de cambio"
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('condicion_tipo_cambio') })}
                            />
                            <label htmlFor="condTipoCambio">Condición del tipo de cambio</label>
                            {props.getFormErrorMessage('condicion_tipo_cambio')}
                        </FloatLabel>
                    </div>
                    {props.formik.values.condicion_tipo_cambio?.d_cond_ti_cam === 1 && (
                        <div className="field col-12 md:col-4">
                            <FloatLabel>
                                <InputNumber
                                    id="tipoCambioOp"
                                    type="text"
                                    locale="es-PY"
                                    value={props.formik.values.d_ti_cam}
                                    onChange={(e) => props.formik.setFieldValue('d_ti_cam', e.value)}
                                    onBlur={() => props.formik.setFieldTouched('d_ti_cam', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_ti_cam') })}
                                />
                                <label htmlFor="tipoCambioOp">Tipo de cambio de la operación</label>
                                {props.getFormErrorMessage('d_ti_cam')}
                            </FloatLabel>
                        </div>
                    )}
                </React.Fragment>
            )}
            {props.emisor?.imputacion_ventas_rg_90 && (
                <div className="field col-12 md:col-6">
                    <FloatLabel>
                        <ImpuestoAfectadoRG90
                            id="imputacionVentasRG90"
                            value={props.formik.values.g_obl_afe}
                            onChange={(e) => props.formik.setFieldValue('g_obl_afe', e)}
                            onBlur={() => props.formik.setFieldTouched('g_obl_afe', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('g_obl_afe') })}
                        />
                        <label htmlFor="imputacionVentasRG90">Impuesto afectado RG90</label>
                        {props.getFormErrorMessage('g_obl_afe')}
                    </FloatLabel>
                </div>
            )}
        </div>
    ): null);
};

export default GOpeCom;
