import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../hooks/useHttpClient';
import { isEmptyArray } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';

const SeleccionarDepartamento = (props) => {
    const httpClient = useHttpClient();
    const [departamentos, setDepartamentos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isEmptyArray(departamentos)) {
            setIsLoading(true);
            httpClient
                .get('api/departamentos')
                .then((response) => {
                    setDepartamentos(response.data);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departamentos]);

    const getSelect = () => {
        if (isLoading) {
            return <Skeleton id={props.id} height={props.skeletonHeigh} width={props.skeletonWidth} className="mb-2"></Skeleton>;
        }
        return <Dropdown id={props.id} filter value={props.value} onChange={(e) => props.onChange(e)} onBlur={() => props.onBlur()} options={departamentos} optionLabel="nombre" placeholder="Seleccionar Departamento" className={props.className} />;
    };

    return getSelect();
};

export default SeleccionarDepartamento;
