import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import SeleccionarDepartamento from '../../../../components/SeleccionarDepartamento';
import SeleccionarDistrito from '../../../../components/SeleccionarDistrito';
import SeleccionarLocalidad from '../../../../components/SeleccionarLocalidad';
import { FloatLabel } from 'primereact/floatlabel';

const GCamSal = (props) => {
    return (
        <>
            <div className="p-fluid formgrid grid">
                <Divider align="center">
                    <b>Campos que identifican el local de salida de las mercaderías</b>
                </Divider>
                <div className="field col-12 md:col-8">
                    <FloatLabel>
                        <InputText
                            id="dirLocSal"
                            value={props.formik.values.d_dir_loc_sal}
                            onChange={(e) => props.formik.setFieldValue('d_dir_loc_sal', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('d_dir_loc_sal', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_dir_loc_sal') })}
                        />
                        <label htmlFor="dirLocSal">Dirección</label>
                        {props.getFormErrorMessage('d_dir_loc_sal')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <InputText
                            id="numCasaSalida"
                            value={props.formik.values.d_num_cas_sal}
                            onChange={(e) => props.formik.setFieldValue('d_num_cas_sal', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('d_num_cas_sal', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_num_cas_sal') })}
                        />
                        <label htmlFor="numCasaSalida">Número de casa</label>
                        {props.getFormErrorMessage('d_num_cas_sal')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <SeleccionarDepartamento
                            id="dptoLocalSalida"
                            value={props.formik.values.dpto_local_salida}
                            onChange={(e) => props.formik.setFieldValue('dpto_local_salida', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('dpto_local_salida', true)}
                            skeletonHeigh={'3rem'}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('dpto_local_salida') })}
                        />
                        <label htmlFor="dptoLocalSalida">Departamento</label>
                        {props.getFormErrorMessage('dpto_local_salida')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <SeleccionarDistrito
                            id="distLocalSalida"
                            departamento={props.formik.values.dpto_local_salida?.codigo}
                            value={props.formik.values.dist_local_salida}
                            onChange={(e) => props.formik.setFieldValue('dist_local_salida', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('dist_local_salida', true)}
                            skeletonHeigh={'3rem'}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('dist_local_salida') })}
                        />
                        <label htmlFor="distLocalSalida">Distrito</label>
                        {props.getFormErrorMessage('dist_local_salida')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <SeleccionarLocalidad
                            id="locLocalSalida"
                            distrito={props.formik.values.dist_local_salida?.codigo}
                            value={props.formik.values.loc_local_salida}
                            onChange={(e) => props.formik.setFieldValue('loc_local_salida', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('loc_local_salida', true)}
                            skeletonHeigh={'3rem'}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('loc_local_salida') })}
                        />
                        <label htmlFor="locLocalSalida">Localidad</label>
                        {props.getFormErrorMessage('loc_local_salida')}
                    </FloatLabel>
                </div>
            </div>
        </>
    );
};

export default GCamSal;
