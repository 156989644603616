import React, { useRef, useState } from 'react';
import { useHttpClient } from '../hooks/useHttpClient';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { BlockUI } from 'primereact/blockui';
import { Tooltip } from 'primereact/tooltip';
import { FileUpload } from 'primereact/fileupload';

const ActualizarImagen = (props) => {
    const [isBlocked, setIsBlocket] = useState(false);
    const httpClient = useHttpClient();
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };
    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Puede arrastrar y soltar sus imagen aquí
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-file', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    const uploadImage = async (event) => {
        const file = event.files[0];

        const extension = file.type.split('/');

        const form = new FormData();

        form.append('extension', extension[1]);
        form.append('image', file);

        setIsBlocket(true);

        const url = props.url ? props.url : 'admin/emisor/actualizar/imagen/';

        httpClient
            .put(url + props.ruc, form)
            .then((response) => {
                setIsBlocket(false);
                props.onSuccess();
            })
            .catch((error) => {
                setIsBlocket(false);
                props.onError(error);
            });
    };

    return (
        <BlockUI blocked={isBlocked}>
            <div className="p-fluid formgrid grid">
                {/*<div className="card">*/}
                <div className="field col-12 md:col-12">
                    <Tooltip target=".custom-choose-btn" content="Seleccionar" position="bottom" />
                    <Tooltip target=".custom-upload-btn" content="Enviar" position="bottom" />
                    <Tooltip target=".custom-cancel-btn" content="Limpiar" position="bottom" />

                    <FileUpload
                        name="certUpload"
                        customUpload
                        ref={fileUploadRef}
                        uploadHandler={uploadImage}
                        onUpload={onTemplateUpload}
                        onSelect={onTemplateSelect}
                        onError={onTemplateClear}
                        onClear={onTemplateClear}
                        headerTemplate={headerTemplate}
                        itemTemplate={itemTemplate}
                        emptyTemplate={emptyTemplate}
                        chooseOptions={chooseOptions}
                        uploadOptions={uploadOptions}
                        cancelOptions={cancelOptions}
                        accept="image/png"
                    />
                </div>
                {/*</div>*/}
            </div>
        </BlockUI>
    );
};

export default ActualizarImagen;
