import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { addLocale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { FloatLabel } from 'primereact/floatlabel';

const GCamNRE = (props) => {
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });
    const motivoEmision = [
        {
            i_mot_emi_nr: 1,
            d_des_mot_emi_nr: 'Traslado por Ventas'
        },
        {
            i_mot_emi_nr: 2,
            d_des_mot_emi_nr: 'Traslado por consignación'
        },
        {
            i_mot_emi_nr: 3,
            d_des_mot_emi_nr: 'Exportación'
        },
        {
            i_mot_emi_nr: 4,
            d_des_mot_emi_nr: 'Traslado por compra'
        },
        {
            i_mot_emi_nr: 5,
            d_des_mot_emi_nr: 'Importación'
        },
        {
            i_mot_emi_nr: 6,
            d_des_mot_emi_nr: 'Traslado por devolución'
        },
        {
            i_mot_emi_nr: 7,
            d_des_mot_emi_nr: 'Traslado entre locales de la empresa'
        },
        {
            i_mot_emi_nr: 8,
            d_des_mot_emi_nr: 'Traslado de bienes por transformación'
        },
        {
            i_mot_emi_nr: 9,
            d_des_mot_emi_nr: 'Traslado de bienes por reparación'
        },
        {
            i_mot_emi_nr: 10,
            d_des_mot_emi_nr: 'Traslado por emisor móvil'
        },
        {
            i_mot_emi_nr: 11,
            d_des_mot_emi_nr: 'Exhibición o Demostración'
        },
        {
            i_mot_emi_nr: 12,
            d_des_mot_emi_nr: 'Participación en ferias'
        },
        {
            i_mot_emi_nr: 13,
            d_des_mot_emi_nr: 'Traslado de encomienda'
        },
        {
            i_mot_emi_nr: 14,
            d_des_mot_emi_nr: 'Decomiso'
        }
    ];

    const responsableEmision = [
        {
            i_resp_emi_nr: 1,
            d_des_resp_emi_nr: 'Emisor de la factura'
        },
        {
            i_resp_emi_nr: 2,
            d_des_resp_emi_nr: 'Poseedor de la factura y bienes'
        },
        {
            i_resp_emi_nr: 3,
            d_des_resp_emi_nr: 'Empresa transportista'
        },
        {
            i_resp_emi_nr: 4,
            d_des_resp_emi_nr: 'Despachante de Aduanas'
        },
        {
            i_resp_emi_nr: 5,
            d_des_resp_emi_nr: 'Agente de transporte o intermediario'
        }
    ];

    return (
        <>
            <div className="p-fluid formgrid grid">
                <Divider align="center">
                    <b>Campos que componen la Nota de Remisión electrónica</b>
                </Divider>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <Dropdown
                            id="modEmision"
                            value={props.formik.values.mot_emision_nr}
                            onChange={(e) => props.formik.setFieldValue('mot_emision_nr', e.value)}
                            onBlur={() => props.formik.setFieldTouched('mot_emision_nr', true)}
                            options={motivoEmision}
                            optionLabel="d_des_mot_emi_nr"
                            placeholder="Seleccione el motivo de la emisión"
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('mot_emision_nr') })}
                        />
                        <label htmlFor="modEmision">Motivo de la emisión</label>
                        {props.getFormErrorMessage('mot_emision_nr')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <Dropdown
                            id="modEmision"
                            value={props.formik.values.resp_emision_nr}
                            onChange={(e) => props.formik.setFieldValue('resp_emision_nr', e.value)}
                            onBlur={() => props.formik.setFieldTouched('resp_emision_nr', true)}
                            options={responsableEmision}
                            optionLabel="d_des_resp_emi_nr"
                            placeholder="Seleccione el responsable de la emisión"
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('resp_emision_nr') })}
                        />
                        <label htmlFor="modEmision">Responsable de la emisión</label>
                        {props.getFormErrorMessage('resp_emision_nr')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-3">
                    <FloatLabel>
                        <InputNumber
                            id="kmRecorrido"
                            value={props.formik.values.d_km_r}
                            onChange={(e) => props.formik.setFieldValue('d_km_r', e.value)}
                            locale="es-PY"
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_km_r') })}
                            onBlur={() => props.formik.setFieldTouched('d_km_r', true)}
                        />
                        <label htmlFor="kmRecorrido">Km. estimados de recorrido</label>
                        {props.getFormErrorMessage('d_km_r')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-3">
                    <FloatLabel>
                        <Calendar
                            id="dFecEm"
                            value={props.formik.values.d_fec_em}
                            onChange={(e) => props.formik.setFieldValue('d_fec_em', e.target.value)}
                            onFocus={() => props.formik.setFieldTouched('d_fec_em', true)}
                            dateFormat="yy-mm-dd"
                            locale="es"
                            selectionMode={'single'}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_fec_em') })}
                        />
                        <label htmlFor="dFecEm">Fecha futura de emisión de la factura</label>
                        {props.getFormErrorMessage('d_fec_em')}
                    </FloatLabel>
                </div>
            </div>
        </>
    );
};

export default GCamNRE;
