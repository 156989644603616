import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Message } from 'primereact/message';
import { login } from './reducer';
import { useDispatch } from 'react-redux';

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showLoginError, setLoginError] = useState(false);
    const dispatch = useDispatch();

    const [, setToken] = useLocalStorage('token', null);

    const doLogin = () => {
        const body = {
            nombre_usuario: username,
            clave: password
        };
        setIsLoading(true);

        axios
            .post(process.env.REACT_APP_LOGIN_URL, body)
            .then((response) => {
                setLoginError(false);
                setToken(response.data);
                const url = process.env.REACT_APP_BACKEND_URL + 'admin/me';
                axios
                    .get(url, { headers: { Authorization: 'Bearer ' + response.data.token } })
                    .then((response) => {
                        dispatch(login({ username: response.data.nombre_usuario, accesos: response.data.accesos, rol: response.data.rol }));
                        setIsLoading(false);
                        navigate('/home');
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setLoginError(true);
                    });
            })
            .catch((error) => {
                setIsLoading(false);
                setLoginError(true);
            });
    };

    useEffect(() => {
        if (isEmpty(username) || isEmpty(password)) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [username, password]);

    const onKeyDownPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            doLogin();
        }
    };

    return (
        <div className="h-screen flex flex-column bg-cover" style={{ backgroundImage: 'url(/layout/images/pages/login-bg.jpg)' }}>
            <div className="align-self-center mt-auto mb-auto text-center">
                <img id="app-logo" className="mb-4" src="/layout/images/logo/logo-light.svg" alt="edoc-logo" style={{ height: '3rem' }} />
                <div className="pages-panel card flex flex-column">
                    {/*<div className="pages-header px-3 py-1">*/}
                    {/*    <h2>E-doc</h2>*/}
                    {/*</div>*/}

                    <h4>Bienvenido</h4>

                    <div className="pages-detail mb-6 px-6">Por favor ingrese sus credenciales</div>

                    {showLoginError && (
                        <div>
                            <Message className="pages-detail mb-6 px-6" severity="error" text="Credenciales incorrectas" />
                        </div>
                    )}

                    <div className="input-panel flex flex-column px-3">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-envelope"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText type="text" id="inputgroup1" onChange={(e) => setUsername(e.target.value)} />
                                <label htmlFor="inputgroup1">Usuario</label>
                            </span>
                        </div>

                        <div className="p-inputgroup mt-3 mb-6">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-lock"></i>
                            </span>
                            <span className="p-float-label">
                                <InputText type="password" id="inputgroup2" onKeyDown={(e) => onKeyDownPress(e)} onChange={(e) => setPassword(e.target.value)} />
                                <label htmlFor="inputgroup2">Clave</label>
                            </span>
                        </div>
                        <div className="flex justify-content-center mb-6">
                            <button className="p-link font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" onClick={() => navigate('/enviar/email')}>
                                ¿Olvidó su contraseña?
                            </button>
                        </div>
                    </div>

                    <Button className="mr-2 mb-2" label="Iniciar sesión" disabled={disabled} loading={isLoading} onClick={doLogin}></Button>
                </div>
            </div>
        </div>
    );
};

export default Login;
