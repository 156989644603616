import { useHttpClient } from '../hooks/useHttpClient';
import { Button } from 'primereact/button';
import React, { useState } from 'react';

const DescargarXML = (props) => {
    const httpClient = useHttpClient();
    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = () => {
        setIsLoading(true);
        httpClient
            .get(`/api/de/${props.edoc.id}/xml`, { headers: { 'Content-Type': 'blob' }, responseType: 'arraybuffer' })
            .then((response) => {
                setIsLoading(false);
                let fileName = `${props.edoc.id}.xml`;
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: 'application/xml',
                        encoding: 'UTF-8'
                    })
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    return <Button type="button" loading={isLoading} icon="pi pi-download" className={props.className} tooltip="Descargar xml" onClick={handleDownload} />;
};

export default DescargarXML;
