import React, { useEffect, useState } from 'react';
import { useHttpClient } from '../hooks/useHttpClient';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { isEmpty } from 'lodash';
import { Skeleton } from 'primereact/skeleton';
import { isEmptyArray } from 'formik';

const SeleccionarEmisor = (props) => {
    const httpClient = useHttpClient();
    const [issuers, setIssuers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState({ code: '', name: '' });
    const [values, setValues] = useState([]);
    const [issuersMap, setIssuersMap] = useState(new Map());

    useEffect(() => {
        if (isEmptyArray(issuers)) {
            setIsLoading(true);
            httpClient
                .get('admin/emisor/all')
                .then((response) => {
                    const tmpIssuers = [];
                    const tmpIssuersMap = new Map();
                    for (let i = 0; i < response.data.length; i++) {
                        const issuer = response.data[i];
                        if (props.filterByTimbrado && !issuer.administrar_timbrado) {
                            continue;
                        }
                        tmpIssuers.push({
                            code: issuer.ruc,
                            name: issuer.razon_social
                        });
                        tmpIssuersMap.set(response.data[i].ruc, {
                            code: issuer.ruc,
                            name: issuer.razon_social
                        });
                    }
                    setIssuers(tmpIssuers);
                    setIssuersMap(tmpIssuersMap);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issuers]);

    useEffect(() => {
        if (!isEmpty(props.isMulti)) {
            const tmpAccess = [];
            for (let i = 0; i < props.value.length; i++) {
                if (issuersMap.has(props.value[i].code)) {
                    tmpAccess.push(issuersMap.get(props.value[i].code));
                }
            }
            setValues(tmpAccess);
            return;
        }
        if (issuersMap.has(props.value?.code)) {
            setValue(issuersMap.get(props.value.code));
        }
    }, [issuersMap, props.value, props.isMulti]);

    const getSelect = () => {
        if (isLoading) {
            return <Skeleton id={props.id} disabled={props.isEditing} height={props.skeletonHeigh} width={props.skeletonWidth} className="mb-2"></Skeleton>;
        }
        if (props.isMulti) {
            return (
                <MultiSelect
                    id={props.id}
                    filter
                    value={values}
                    onChange={(e) => props.onChange(e.value)}
                    onBlur={() => props.onBlur(true)}
                    options={issuers}
                    optionLabel="name"
                    display="chip"
                    disabled={props.disabled}
                    placeholder={props.placeHolder}
                    className={props.className}
                />
            );
        }
        return (
            <Dropdown
                id={props.id}
                filter
                disabled={props.disabled}
                value={value}
                onChange={(e) => props.onChange(e.value)}
                onBlur={() => props.onBlur(true)}
                options={issuers}
                optionLabel="name"
                placeholder={props.placeHolder}
                className={props.className}
            />
        );
    };

    return getSelect();
};
export default SeleccionarEmisor;
