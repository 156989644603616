import { useHttpClient } from '../hooks/useHttpClient';
import React, { useEffect, useState } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { FloatLabel } from 'primereact/floatlabel';

const EnviarAssets = (props) => {
    const httpClient = useHttpClient();
    const [visible, setVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setEmail(props.edoc?.g_dat_gral_ope?.g_dat_rec?.d_email_rec);
    }, [props.edoc?.g_dat_gral_ope?.g_dat_rec?.d_email_rec]);

    const accept = () => {
        setIsLoading(true);
        const body = {
            cdc: props.edoc.id,
            email: email
        };

        httpClient
            .post('admin/enviar/assets', body)
            .then((response) => {
                props.onSuccess(response);
                setIsLoading(false);
                setVisible(false);
                setEmail('');
            })
            .catch((error) => {
                props.onError(error);
                setIsLoading(false);
                setVisible(false);
                setEmail('');
            });
    };

    const reject = () => {};

    const confirmarEnvio = () => {
        confirmDialog({
            message: 'Esta acción enviará un correo electrónico al cliente, si un correo no es proveído, se utilizará el correo electrónico proveído al crear el documento electrónico',
            header: 'Atención',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Si',
            accept,
            reject
        });
    };

    return (
        <div>
            <Dialog
                header="Enviar KuDE"
                visible={visible}
                modal
                style={{ width: '250px', height: '200px' }}
                onHide={() => {
                    setVisible(false);
                }}
            >
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-12" style={{ marginTop: '1rem' }}>
                        <FloatLabel>
                            {props.isLoading ? <Skeleton id="motivo" height="3rem" width="20rem" className="mb-12"></Skeleton> : <InputText id="motivo" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />}
                            <label htmlFor="motivo">Correo electrónico</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className="flex flex-wrap justify-content-end">
                    <Button type="button" className="field col-12 md:col-3" onClick={() => confirmarEnvio()} label="Enviar" />
                </div>
            </Dialog>
            <Button type="button" icon="pi pi-envelope" loading={isLoading} className={props.className} tooltip="Enviar correo" onClick={() => setVisible(true)} />
        </div>
    );
};

export default EnviarAssets;
