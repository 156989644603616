import React, { useMemo, useRef, useState } from 'react';
import { useHttpClient } from '../../../hooks/useHttpClient';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import SeleccionarEmisor from '../../../components/SeleccionarEmisor';
import { FloatLabel } from 'primereact/floatlabel';

const CrearUsuario = () => {
    const [edit, setEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const rolesItems = [
        { name: 'generar_de', code: 1 },
        { name: 'root', code: 2 },
        { name: 'admin', code: 3 }
    ];

    const httpClient = useHttpClient();
    const [searchParams] = useSearchParams();
    const userName = searchParams.get('nombreUsuario');
    const navigate = useNavigate();

    const toast = useRef(null);

    const validationSchema = Yup.object().shape({
        nombre_usuario: Yup.string().required('Campo requerido'),
        empresa: Yup.object({
            name: Yup.string(),
            code: Yup.string()
        }).required('Campo requerido'),
        nombres: Yup.string().optional(),
        rol: Yup.object({
            name: Yup.string(),
            code: Yup.number()
        }).required('Campo requerido'),
        email: Yup.string().email('Debe ser un email válido').required('Campo requerido'),
        accesos: Yup.array()
            .of(
                Yup.object({
                    name: Yup.string(),
                    code: Yup.string()
                })
            )
            .required('Campo requerido')
    });

    const onSubmit = (values) => {
        const url = edit ? process.env.REACT_APP_BACKEND_URL + `admin/usuario/actualizar` : process.env.REACT_APP_BACKEND_URL + `admin/usuario`;

        const tmpAccesos = [];

        for (let i = 0; i < values.accesos.length; i++) {
            tmpAccesos.push(values.accesos[i].code);
        }

        const user = {
            ...values,
            empresa: values.empresa.code,
            rol: values.rol.name,
            accesos: tmpAccesos
        };

        httpClient
            .post(url, user)
            .then(() => {
                navigate('/administracion/usuarios');
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error al crear el usuario', detail: error.response.data.error, life: 10000 });
            });
    };

    const formik = useFormik({
        initialValues: {
            nombre_usuario: '',
            empresa: '',
            nombres: '',
            rol: rolesItems[0],
            email: '',
            accesos: [{ code: '', name: '' }]
        },
        validationSchema,
        onSubmit
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };

    const titlePage = useMemo(() => {
        if (userName) {
            setEdit(true);
            setIsLoading(true);
            httpClient
                .get(`admin/usuario/${userName}`)
                .then((response) => {
                    formik.setFieldValue('nombre_usuario', response.data.nombre_usuario);
                    formik.setFieldValue('empresa', { code: response.data.empresa, name: '' });
                    formik.setFieldValue('nombres', response.data.nombres);
                    formik.setFieldValue('email', response.data.email);

                    for (let i = 0; i < rolesItems.length; i++) {
                        if (response.data.rol === rolesItems[i].name) {
                            formik.setFieldValue('rol', rolesItems[i]);
                            break;
                        }
                    }
                    const accesos = [];

                    for (let j = 0; j < response.data.accesos.length; j++) {
                        accesos.push({ code: response.data.accesos[j], name: '' });
                    }
                    formik.setFieldValue('accesos', accesos);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                });
            return 'Actualizar Usuario';
        }
        return 'Crear Usuario';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userName]);

    const issuerOnChange = (value) => {
        formik.setFieldValue('empresa', value);
    };

    const issuerOnBlur = (value) => {
        formik.setFieldTouched('empresa', value);
    };

    const accessOnChange = (value) => {
        formik.setFieldValue('accesos', value);
    };

    const accessOnBlur = (value) => {
        formik.setFieldTouched('accesos', value);
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div className="col-12">
                    <div className="card">
                        <h5>{titlePage}</h5>
                        <Toast ref={toast} />
                        <form onSubmit={formik.handleSubmit} className="flex flex-column gap-2">
                            <div className="p-fluid formgrid grid">
                                <div className="field col-12 md:col-6">
                                    {isLoading ? (
                                        <Skeleton id="nombreUsuario" height="3rem" className="mb-2"></Skeleton>
                                    ) : (
                                        <FloatLabel>
                                            <InputText
                                                id="nombreUsuario"
                                                type="text"
                                                disabled={edit}
                                                value={formik.values.nombre_usuario}
                                                onChange={(e) => formik.setFieldValue('nombre_usuario', e.target.value)}
                                                onBlur={() => formik.setFieldTouched('nombre_usuario', true)}
                                                className={classNames({ 'p-invalid': isFormFieldInvalid('nombre_usuario') })}
                                            />
                                            <label htmlFor="nombreUsuario">
                                                Nombre de usuario<span className="pink-color"> *</span>
                                            </label>
                                            {getFormErrorMessage('nombre_usuario')}
                                        </FloatLabel>
                                    )}
                                </div>
                                <div className="field col-12 md:col-6">
                                    {isLoading ? (
                                        <Skeleton id="empresa" height="3rem" className="mb-2"></Skeleton>
                                    ) : (
                                        <FloatLabel>
                                            <SeleccionarEmisor
                                                id="empresa"
                                                value={formik.values.empresa}
                                                onChange={issuerOnChange}
                                                onBlur={issuerOnBlur}
                                                skeletonHeigh={'3rem'}
                                                placeHolder="Seleccione una empresa"
                                                className={classNames({ 'p-invalid': isFormFieldInvalid('empresa') })}
                                            />
                                            <label htmlFor="empresa">
                                                Empresa<span className="pink-color"> *</span>
                                            </label>
                                            {getFormErrorMessage('empresa')}
                                        </FloatLabel>
                                    )}
                                </div>
                                <div className="field col-12 md:col-6">
                                    {isLoading ? (
                                        <Skeleton id="nombres" height="3rem" className="mb-2"></Skeleton>
                                    ) : (
                                        <span className="p-float-label">
                                            <InputText
                                                id="nombres"
                                                type="text"
                                                value={formik.values.nombres}
                                                onChange={(e) => formik.setFieldValue('nombres', e.target.value)}
                                                onBlur={() => formik.setFieldTouched('nombres', true)}
                                                className={classNames({ 'p-invalid': isFormFieldInvalid('nombres') })}
                                            />
                                            <label htmlFor="nombres">Nombre y Apellido</label>
                                            {getFormErrorMessage('nombres')}
                                        </span>
                                    )}
                                </div>
                                <div className="field col-12 md:col-6">
                                    {isLoading ? (
                                        <Skeleton id="email" height="3rem" className="mb-2"></Skeleton>
                                    ) : (
                                        <FloatLabel>
                                            <InputText
                                                id="email"
                                                type="text"
                                                value={formik.values.email}
                                                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                                                onBlur={() => formik.setFieldTouched('email', true)}
                                                className={classNames({ 'p-invalid': isFormFieldInvalid('email') })}
                                            />
                                            <label htmlFor="email">
                                                Email<span className="pink-color"> *</span>
                                            </label>
                                            {getFormErrorMessage('email')}
                                        </FloatLabel>
                                    )}
                                </div>
                                <div className="field col-12 md:col-3">
                                    {isLoading ? (
                                        <Skeleton id="rol" height="3rem" className="mb-2"></Skeleton>
                                    ) : (
                                        <FloatLabel>
                                            <Dropdown
                                                id="rol"
                                                value={formik.values.rol}
                                                onChange={(e) => formik.setFieldValue('rol', e.value)}
                                                onBlur={() => formik.setFieldTouched('rol', true)}
                                                options={rolesItems}
                                                optionLabel="name"
                                                placeholder="Seleccione el rol del usuario"
                                                className={classNames({ 'p-invalid': isFormFieldInvalid('rol') })}
                                            />
                                            <label htmlFor="rol">
                                                Rol<span className="pink-color"> *</span>
                                            </label>
                                            {getFormErrorMessage('rol')}
                                        </FloatLabel>
                                    )}
                                </div>
                                <div className="field col-12 md:col-12">
                                    {isLoading ? (
                                        <Skeleton id="accesos" height="3rem" className="mb-2"></Skeleton>
                                    ) : (
                                        <FloatLabel>
                                            <SeleccionarEmisor
                                                id="accesos"
                                                value={formik.values.accesos}
                                                onChange={accessOnChange}
                                                onBlur={accessOnBlur}
                                                skeletonHeigh={'3rem'}
                                                isMulti="true"
                                                placeHolder="Seleccione los accesos"
                                                className={classNames({ 'p-invalid': isFormFieldInvalid('accesos') })}
                                            />
                                            <label htmlFor="accesos">
                                                Accesos<span className="pink-color"> *</span>
                                            </label>
                                            {getFormErrorMessage('accesos')}
                                        </FloatLabel>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-content-end gap-2">
                                <Button type="button" className=" p-button-danger field col-12 md:col-1" onClick={() => navigate('/administracion/usuarios')} label="Cancelar" />
                                <Button type="submit" className="field col-12 md:col-1" label="Guardar" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CrearUsuario;
