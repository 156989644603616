import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useHttpClient } from '../../hooks/useHttpClient';
import { Dialog } from 'primereact/dialog';
import ActualizarCertficado from '../../components/ActualizarCertificado';
import { Toast } from 'primereact/toast';
import { Tag } from 'primereact/tag';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import { ConfirmDialog } from 'primereact/confirmdialog';
import ActualizarImagen from '../../components/ActualizarImagen';

const Emisor = (props) => {
    const httpClient = useHttpClient();
    const navigate = useNavigate();
    const [issuers, setIssuers] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filters] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [displayImageDialog, setDisplayImageDialog] = useState(false);
    const [selectedIssuer, setSelectedIssuer] = useState({});
    const [expandedRows, setExpandedRows] = useState(null);
    const [trigger, setTrigger] = useState(true);
    const [openModalCertUpdate, setOpenModalCertUpdate] = useState(false);
    const [openModalCertDelete, setOpenModalCertDelete] = useState(false);
    const toast = useRef(null);
    const certIdRef = useRef('');
    const rucRef = useRef('');

    useEffect(() => {
        if (isEmpty(issuers) || trigger) {
            httpClient
                .get('admin/emisor/all')
                .then((response) => {
                    setIssuers(response.data);
                    setLoading(false);
                    setTrigger(false);
                    setExpandedRows(null);
                })
                .catch((error) => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issuers, trigger]);

    const canCreateOrUpdate = useMemo(() => {
        return props.role === 'root';
    }, [props.role]);

    const createIssuer = () => {
        navigate('/administracion/emisores/emisor');
    };

    const editIssuer = (ruc) => {
        navigate(`/administracion/emisores/emisor?ruc=${ruc}`);
    };

    const acceptEmailUpdateCert = () => {
        httpClient
            .post(`admin/emisor/email/actualizarCert`, { ruc: rucRef.current })
            .then((response) => {
                setTrigger(true);
                toast.current.show({ severity: 'success', summary: 'Operación realizada', detail: `Correo enviado al emisor`, life: 10000 });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: `Error al enviar el correo`, life: 10000 });
            });
    };

    const actionTemplate = (rowData) => {
        if (canCreateOrUpdate) {
            return (
                <div className="flex flex-wrap justify-content-center gap-2">
                    <Button type="button" icon="pi pi-pencil" className="p-button-rounded p-button-info p-button-text mr-2 mb-2" tooltip="Modificar emisor" onClick={() => editIssuer(rowData.ruc)} />
                    <Button
                        type="button"
                        icon="pi pi-verified"
                        className="p-button-rounded p-button-success p-button-text mr-2 mb-2"
                        tooltip="Agregar certificado"
                        onClick={() => {
                            setSelectedIssuer(rowData);
                            setDisplayDialog(true);
                        }}
                    />

                    <Button
                        type="button"
                        icon="pi pi-at"
                        className="p-button-rounded p-button-warn p-button-text mr-2 mb-2"
                        tooltip="Enviar email para actualizar certificado"
                        onClick={() => {
                            rucRef.current = rowData.ruc;
                            setOpenModalCertUpdate(true);
                        }}
                    />
                    <Button
                        type="button"
                        icon="pi pi-image"
                        className="p-button-rounded p-button-info p-button-text mr-2 mb-2"
                        tooltip="Actualizar imagen KuDE"
                        onClick={() => {
                            rucRef.current = rowData.ruc;
                            setDisplayImageDialog(true);
                        }}
                    />
                </div>
            );
        }

        return (
            <div className="flex flex-wrap justify-content-center gap-2">
                <Button
                    type="button"
                    icon="pi pi-key"
                    className="p-button-rounded p-button-success p-button-text mr-2 mb-2"
                    tooltip="Agregar certificado"
                    onClick={() => {
                        setSelectedIssuer(rowData);
                        setDisplayDialog(true);
                    }}
                />

                <Button
                    type="button"
                    icon="pi pi-at"
                    className="p-button-rounded p-button-warn p-button-text mr-2 mb-2"
                    tooltip="Enviar email para actualizar certificado"
                    onClick={() => {
                        rucRef.current = rowData.ruc;
                        setOpenModalCertUpdate(true);
                    }}
                />
            </div>
        );
    };

    const onCertUpdateSuccess = () => {
        setDisplayDialog(false);
        setTrigger(true);
        toast.current.show({ severity: 'success', summary: 'Certificado agregado', detail: `Emisor: ${selectedIssuer.ruc}`, life: 3000 });
    };
    const onCertUpdateError = (error) => {
        setDisplayDialog(false);
        toast.current.show({ severity: 'error', summary: 'Error al agregar certificado', detail: error.response.data.error, life: 3000 });
    };

    const onImageUpdateSuccess = () => {
        setDisplayImageDialog(false);
        setTrigger(true);
        toast.current.show({ severity: 'success', summary: 'Imagen agregada', detail: `Emisor: ${rucRef.current}`, life: 3000 });
    };

    const onImageUpdateError = (error) => {
        setDisplayDialog(false);
        toast.current.show({ severity: 'error', summary: 'Error al agregar la imagen', detail: error.response.data.error, life: 3000 });
    };

    const header = () => {
        if (canCreateOrUpdate) {
            return (
                <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                    <span className="text-xl text-900 font-bold">Emisores</span>
                    <Button icon="pi pi-plus" onClick={() => createIssuer()} />
                </div>
            );
        }

        return (
            <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                <span className="text-xl text-900 font-bold">Emisores</span>
            </div>
        );
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">
                <ConfirmDialog />
                <h5>Certificados</h5>
                <DataTable value={data.certificados} responsiveLayout="stack">
                    <Column field="id" header="Id"></Column>
                    <Column field="valid_from" header="Válido desde" body={formatNotBeforeDate}></Column>
                    <Column field="valid_until" header="Válido hasta" body={formatNotAfterDate}></Column>
                    <Column field="estado" header="Estado" body={certStatusBodyTemplate}></Column>
                    <Column field="acciones" header="Acciones" body={rowExpansionActionTemplate}></Column>
                </DataTable>
            </div>
        );
    };

    const formatNotBeforeDate = (row) => {
        const date = Date.parse(row.valid_from);
        return format(date, 'dd-MM-yyyy HH:mm:ss');
    };

    const formatNotAfterDate = (row) => {
        const date = Date.parse(row.valid_until);
        return format(date, 'dd-MM-yyyy HH:mm:ss');
    };

    const certStatusBodyTemplate = (rowData) => {
        return <Tag value={rowData.estado} severity={getDueDateSeverity(rowData.estado)}></Tag>;
    };

    const getDueDateSeverity = (estado) => {
        switch (estado) {
            case 'Válido':
                return 'success';

            case 'Vencido':
                return 'danger';

            case 'Inactivo':
                return 'warning';

            default:
                return '';
        }
    };

    const rowExpansionActionTemplate = (row) => {
        return (
            <Button
                type="button"
                icon="pi pi-times"
                className="p-button-rounded p-button-danger p-button-text mr-2 mb-2"
                tooltip="Eliminar certificado"
                onClick={() => {
                    certIdRef.current = row.id;
                    setOpenModalCertDelete(true);
                }}
            />
        );
    };

    const acceptDeleteCert = () => {
        httpClient
            .delete(`admin/emisor/eliminar/cert/${selectedIssuer.ruc}/${certIdRef.current}`)
            .then((response) => {
                setTrigger(true);
                toast.current.show({ severity: 'success', summary: 'Certificado eliminado', detail: `Certificado con id ${certIdRef.current} eliminado`, life: 3000 });
            })
            .catch((error) => {
                toast.current.show({ severity: 'error', summary: 'Error al eliminar el certificado', detail: error.response.data.error, life: 3000 });
            });
    };

    const reject = () => {};

    const allowExpansion = (rowData) => {
        return !isEmpty(rowData.certificados);
    };

    const onRowExpand = (event) => {
        setSelectedIssuer(event.data);
    };

    return (
        <div className="grid table-demo">
            <Toast ref={toast} />
            <ConfirmDialog
                visible={openModalCertDelete}
                onHide={() => setOpenModalCertDelete(false)}
                message="¿Está seguro de querer eliminar el certificado seleccionado?"
                header="Confirmación"
                acceptLabel="Sí"
                icon="pi pi-exclamation-triangle"
                accept={acceptDeleteCert}
                reject={reject}
            />
            <ConfirmDialog
                visible={openModalCertUpdate}
                onHide={() => setOpenModalCertUpdate(false)}
                message="Está acción enviará un correo al emisor. ¿Está seguro de querer realizarlo?"
                header="Confirmación"
                acceptLabel="Sí"
                icon="pi pi-exclamation-triangle"
                accept={acceptEmailUpdateCert}
                reject={reject}
            />
            <div className="col-12">
                <div className="card">
                    <DataTable
                        value={issuers}
                        header={header}
                        className="mt-3"
                        dataKey="ruc"
                        filters={filters}
                        filterDisplay="row"
                        loading={loading}
                        stripedRows
                        responsiveLayout="stack"
                        emptyMessage="No existen emisores"
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        onRowExpand={onRowExpand}
                    >
                        <Column expander={allowExpansion} style={{ width: '1rem' }} />
                        <Column field="ruc" header="RUC" sortable filter filterPlaceholder="Filtrar por RUC" style={{ minWidth: '2rem' }} />
                        <Column field="razon_social" header="Razón Social" filter sortable filterPlaceholder="Filtrar por Razón Social" style={{ minWidth: '7rem' }} />
                        <Column header="Acciones" style={{ flex: '0 0 2rem', minWidth: '3rem' }} body={actionTemplate}></Column>
                    </DataTable>
                </div>
            </div>
            <Dialog
                header="Actualizar Certificado"
                visible={displayDialog}
                style={{ maxWidth: '30vw' }}
                modal
                // footer={basicDialogFooter}
                onHide={() => setDisplayDialog(false)}
            >
                <ActualizarCertficado ruc={selectedIssuer.ruc} onSuccess={onCertUpdateSuccess} onError={onCertUpdateError} />
            </Dialog>

            <Dialog
                header="Actualizar Imagen"
                visible={displayImageDialog}
                style={{ maxWidth: '30vw' }}
                modal
                // footer={basicDialogFooter}
                onHide={() => setDisplayImageDialog(false)}
            >
                <ActualizarImagen ruc={rucRef.current} onSuccess={onImageUpdateSuccess} onError={onImageUpdateError} />
            </Dialog>
        </div>
    );
};

export default Emisor;
