import { useHttpClient } from '../hooks/useHttpClient';
import React, { useState } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import { isEmpty } from 'lodash';
import { Dialog } from 'primereact/dialog';
import { FloatLabel } from 'primereact/floatlabel';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';

const AnularDocumentoElectronico = (props) => {
    const httpClient = useHttpClient();
    const [visible, setVisible] = useState(false);
    const [reason, setReason] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const accept = () => {
        setIsLoading(true);
        const body = {
            nro_timbrado: props.edoc?.g_timb?.d_num_tim,
            establecimiento: props.edoc?.g_timb?.d_est,
            punto_expedicion: props.edoc?.g_timb?.d_pun_exp,
            numero_inicio: props.edoc?.g_timb?.d_num_doc,
            numero_fin: props.edoc?.g_timb?.d_num_doc,
            tipo_de: props.edoc?.g_timb?.i_ti_de,
            motivo: reason,
            ruc: props.edoc?.g_dat_gral_ope?.g_emis?.d_ruc_em
        };
        httpClient
            .delete(`/api/evento/inutilizar`, { data: body })
            .then((response) => {
                props.onSuccess(response);
                setIsLoading(false);
                setVisible(false);
                setReason('');
            })
            .catch((error) => {
                props.onError(error);
                setIsLoading(false);
                setVisible(false);
                setReason('');
            });
    };

    const reject = () => {};

    const confirmCancellation = () => {
        confirmDialog({
            message: 'Está acción enviará un evento de inutilización a SIFEN. ¿Está seguro/a de realizar esta acción?',
            header: 'Atención',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Si',
            accept,
            reject
        });
    };

    const getFormErrorMessage = () => {
        return isEmpty(reason) ? <small className="p-error">Campo obligatorio</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <div>
            <Dialog
                header="Anular Documento Electrónico"
                visible={visible}
                modal
                style={{ width: '400px', height: '220px' }}
                onHide={() => {
                    setVisible(false);
                }}
            >
                <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-12" style={{ marginTop: '1rem' }}>
                        <FloatLabel>
                            <InputText id="motivo" type="text" value={reason} onChange={(e) => setReason(e.target.value)} className={classNames({ 'p-invalid': isEmpty(reason) })} />
                            <label htmlFor="motivo">Motivo</label>
                            {getFormErrorMessage()}
                        </FloatLabel>
                    </div>
                </div>
                <div className="flex flex-wrap justify-content-end">
                    <Button type="button" loading={isLoading} className="field col-12 md:col-3" disabled={isEmpty(reason)} onClick={() => confirmCancellation()} label="Guardar" />
                </div>
            </Dialog>
            <Button type="button" icon="pi pi-times-circle" loading={isLoading} className={props.className} tooltip="Anular" onClick={() => setVisible(true)} />
        </div>
    );
};

export default AnularDocumentoElectronico;
