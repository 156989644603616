import { createSlice } from '@reduxjs/toolkit';

export const dashboardReducer = createSlice({
    name: 'dashboard',
    initialState: {
        issuer: { code: '', name: '' }
    },
    reducers: {
        updateIssuer: (state, action) => {
            state.issuer = action.payload.issuer;
        }
    }
});

export const { updateIssuer } = dashboardReducer.actions;

export default dashboardReducer.reducer;
