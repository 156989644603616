import { ObjectUtils } from 'primereact/utils';
import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../LayoutContext';
import { Link, useParams, useSearchParams } from 'react-router-dom';

const AppBreadcrumb = () => {
    const pathname = useParams();
    const [searchParams] = useSearchParams();
    const [breadcrumb, setBreadcrumb] = useState(null);
    const { breadcrumbs } = useContext(LayoutContext);

    useEffect(() => {
        const filteredBreadcrumbs = breadcrumbs?.find((crumb) => {
            const path = pathname['*'];
            return crumb.to?.startsWith('/' + path);
            //return crumb.to?.replace(/\/$/, ' ') === path.replace(/\/$/, '');

            //return crumb;
        });
        setBreadcrumb(filteredBreadcrumbs ?? null);
    }, [pathname, breadcrumbs]);

    return (
        <div className="layout-breadcrumb-container">
            <nav className="layout-breadcrumb">
                <ol>
                    <li>
                        <Link to={'/'} style={{ color: 'inherit' }}>
                            <i className="pi pi-home"></i>
                        </Link>
                    </li>
                    {ObjectUtils.isNotEmpty(breadcrumb?.labels)
                        ? breadcrumb?.labels?.map((label, index) => {
                              return (
                                  <React.Fragment key={index}>
                                      <i className="pi pi-angle-right"></i>
                                      <li key={index}>{label}</li>
                                  </React.Fragment>
                              );
                          })
                        : pathname + searchParams.toString() === '/' && (
                              <>
                                  <i className="pi pi-angle-right"></i>
                                  <li key={'home'}>Sales Dashboard</li>
                              </>
                          )}
                </ol>
            </nav>
        </div>
    );
};

export default AppBreadcrumb;
