import AppSubMenu from './AppSubMenu';
import { useMemo } from 'react';

const AppMenu = (props) => {
    const model = useMemo(() => {
        if (props.role === 'root') {
            return [
                {
                    label: 'Dashboards',
                    icon: 'pi pi-fw pi-home',
                    items: [{ label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/home' }]
                },
                {
                    label: 'Administración',
                    icon: 'pi pi-fw pi-star-fill',
                    items: [
                        { label: 'Emisores', icon: 'pi pi-fw pi-building', to: '/administracion/emisores' },
                        { label: 'Usuarios', icon: 'pi pi-fw pi-users', to: '/administracion/usuarios' },
                        { label: 'Documentos', icon: 'pi pi-fw pi-book', to: '/administracion/documentos' }
                    ]
                }
            ];
        }

        if (props.role === 'admin') {
            return [
                {
                    label: 'Dashboards',
                    icon: 'pi pi-fw pi-home',
                    items: [{ label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/home' }]
                },
                {
                    label: 'Administración',
                    icon: 'pi pi-fw pi-star-fill',
                    items: [
                        { label: 'Emisores', icon: 'pi pi-fw pi-building', to: '/administracion/emisores' },
                        { label: 'Usuarios', icon: 'pi pi-fw pi-users', to: '/administracion/usuarios' },
                        { label: 'Documentos', icon: 'pi pi-fw pi-book', to: '/administracion/documentos' }
                    ]
                }
            ];
        }
    }, [props.role]);

    return <AppSubMenu model={model} />;
};

export default AppMenu;
