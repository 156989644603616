import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { classNames, DomHandler } from 'primereact/utils';
import { Navigate, Route, Routes, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { PrimeReactContext } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { useSelector } from 'react-redux';
import Dashboard from './pages/Dashboard/Dashboard';
import Emisor from './pages/Emisor/Emisor';
import CrearEmisor from './pages/Emisor/CrearEmisor';
import Usuario from './pages/Usuario/Usuario';
import CrearUsuario from './pages/Usuario/CrearUsuario/CrearUsuario';
import DocumentosElectronicos from './pages/DocumentosElectronicos/DocumentosElectronicos';
import DetalleDocumentoElectronico from './pages/DocumentosElectronicos/DetalleDocumentoElectronico/DetalleDocumentoElectronico';
import CrearDocumentoElectronico from './pages/DocumentosElectronicos/CrearDocumentoElectronico/CrearDocumentoElectronico';
import { LayoutContext } from './LayoutContext';
import AppTopbar from './components/AppTopbar';
import { useEventListener, useMountEffect, useResizeListener, useUnmountEffect } from 'primereact/hooks';
import AppSidebar from './components/AppSidebar';
import AppBreadcrumb from './components/AppBreadCrumb';

const App = () => {
    const { layoutConfig, layoutState, setLayoutState, setLayoutConfig, isSlim, isSlimPlus, isHorizontal, isDesktop, isSidebarActive } = useContext(LayoutContext);
    const topbarRef = useRef(null);
    const sidebarRef = useRef(null);
    const { setRipple } = useContext(PrimeReactContext);
    let timeout: NodeJS.Timeout | null = null;
    const pathname = useParams();
    const [searchParams] = useSearchParams();

    //BEFORE
    const [colorMode] = useState('light');
    const [newThemeLoaded, setNewThemeLoaded] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    const role = useSelector((state) => state.session.rol);

    PrimeReactContext.ripple = true;

    const [bindMenuOutsideClickListener, unbindMenuOutsideClickListener] = useEventListener({
        type: 'click',
        listener: (event) => {
            const isOutsideClicked = !(sidebarRef.current?.isSameNode(event.target) || sidebarRef.current?.contains(event.target) || topbarRef.current?.menubutton?.isSameNode(event.target) || topbarRef.current?.menubutton?.contains(event.target));

            if (isOutsideClicked) {
                hideMenu();
            }
        }
    });

    const [bindDocumentResizeListener, unbindDocumentResizeListener] = useResizeListener({
        listener: () => {
            if (isDesktop() && !DomHandler.isTouchDevice()) {
                hideMenu();
            }
        }
    });

    const hideMenu = useCallback(() => {
        setLayoutState((prevLayoutState) => ({
            ...prevLayoutState,
            overlayMenuActive: false,
            overlaySubmenuActive: false,
            staticMenuMobileActive: false,
            menuHoverActive: false,
            resetMenu: (isSlim() || isSlimPlus() || isHorizontal()) && isDesktop()
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSlim, isSlimPlus, isHorizontal, isDesktop]);

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };
    useMountEffect(() => {
        setRipple?.(layoutConfig.ripple);
    });

    const onMouseEnter = () => {
        if (!layoutState.anchored) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            setLayoutState((prevLayoutState) => ({
                ...prevLayoutState,
                sidebarActive: true
            }));
        }
    };

    const onMouseLeave = () => {
        if (!layoutState.anchored) {
            if (!timeout) {
                timeout = setTimeout(
                    () =>
                        setLayoutState((prevLayoutState) => ({
                            ...prevLayoutState,
                            sidebarActive: false
                        })),
                    300
                );
            }
        }
    };

    useEffect(() => {
        const onRouteChange = () => {
            if (layoutConfig.colorScheme === 'dark') {
                setLayoutConfig((prevState) => ({ ...prevState, menuTheme: 'dark' }));
            }
        };
        onRouteChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, searchParams]);

    useEffect(() => {
        if (isSidebarActive()) {
            bindMenuOutsideClickListener();
        }

        if (layoutState.staticMenuMobileActive) {
            blockBodyScroll();
            (isSlim() || isSlimPlus() || isHorizontal()) && bindDocumentResizeListener();
        }

        return () => {
            unbindMenuOutsideClickListener();
            unbindDocumentResizeListener();
            unblockBodyScroll();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutState.overlayMenuActive, layoutState.staticMenuMobileActive, layoutState.overlaySubmenuActive]);

    useUnmountEffect(() => {
        unbindMenuOutsideClickListener();
    });

    const determinedRoutes = useMemo(() => {
        if (role === 'root') {
            return (
                <Routes>
                    <Route path="/home" element={<Dashboard colorMode={colorMode} isNewThemeLoaded={newThemeLoaded} onNewThemeChange={(e) => setNewThemeLoaded(e)} location={location} />} />
                    <Route path="/administracion/emisores" element={<Emisor role={role} />} />
                    <Route path="/administracion/emisores/emisor" element={<CrearEmisor />} />
                    <Route path="/administracion/usuarios" element={<Usuario role={role} />} />
                    <Route path="/administracion/usuarios/usuario" element={<CrearUsuario />} />
                    <Route path="/administracion/documentos" element={<DocumentosElectronicos />} />
                    <Route path="/administracion/documentos/:cdc" element={<DetalleDocumentoElectronico />} />
                    <Route path="/administracion/documentos/crear" element={<CrearDocumentoElectronico />} />
                    <Route path="/administracion/documentos/actualizar/:cdc" element={<CrearDocumentoElectronico />} />
                    <Route path="*" element={<Navigate to="/notfound" replace />} />
                </Routes>
            );
        }

        if (role === 'admin') {
            return (
                <Routes>
                    <Route path="/home" element={<Dashboard colorMode={colorMode} isNewThemeLoaded={newThemeLoaded} onNewThemeChange={(e) => setNewThemeLoaded(e)} location={location} />} />
                    <Route path="/administracion/emisores" element={<Emisor role={role} />} />
                    <Route path="/administracion/usuarios" element={<Usuario role={role} />} />
                    <Route path="/administracion/documentos" element={<DocumentosElectronicos />} />
                    <Route path="/administracion/documentos/:cdc" element={<DetalleDocumentoElectronico />} />
                    <Route path="/administracion/documentos/crear" element={<CrearDocumentoElectronico />} />
                    <Route path="*" element={<Navigate to="/notfound" replace />} />
                </Routes>
            );
        }

        return null;
    }, [role, colorMode, location, newThemeLoaded]);

    const containerClassName = classNames('layout-topbar-' + layoutConfig.topbarTheme, 'layout-menu-' + layoutConfig.menuTheme, 'layout-menu-profile-' + layoutConfig.menuProfilePosition, {
        'layout-overlay': layoutConfig.menuMode === 'overlay',
        'layout-static': layoutConfig.menuMode === 'static',
        'layout-slim': layoutConfig.menuMode === 'slim',
        'layout-slim-plus': layoutConfig.menuMode === 'slim-plus',
        'layout-horizontal': layoutConfig.menuMode === 'horizontal',
        'layout-reveal': layoutConfig.menuMode === 'reveal',
        'layout-drawer': layoutConfig.menuMode === 'drawer',
        'p-input-filled': layoutConfig.inputStyle === 'filled',
        'layout-sidebar-dark': layoutConfig.colorScheme === 'dark',
        'p-ripple-disabled': !layoutConfig.ripple,
        'layout-static-inactive': layoutState.staticMenuDesktopInactive && layoutConfig.menuMode === 'static',
        'layout-overlay-active': layoutState.overlayMenuActive,
        'layout-mobile-active': layoutState.staticMenuMobileActive,
        'layout-topbar-menu-active': layoutState.topbarMenuActive,
        'layout-menu-profile-active': layoutState.menuProfileActive,
        'layout-sidebar-active': layoutState.sidebarActive,
        'layout-sidebar-anchored': layoutState.anchored
    });

    return (
        <div className={classNames('layout-container', containerClassName)}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
            <AppTopbar ref={topbarRef} role={role} />
            <div ref={sidebarRef} className="layout-sidebar" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <AppSidebar role={role} />
            </div>

            <div className="layout-content-wrapper">
                <AppBreadcrumb></AppBreadcrumb>
                <div className="layout-content">{determinedRoutes}</div>
            </div>
        </div>
    );
};

export default App;
