import React, { useContext, useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { Chart } from 'primereact/chart';
import { Button } from 'primereact/button';
import { isEmpty } from 'lodash';
import { useHttpClient } from '../../hooks/useHttpClient';
import { useDispatch, useSelector } from 'react-redux';
import { updateIssuer } from './reducer';
import SeleccionarEmisor from '../../components/SeleccionarEmisor';
import { LayoutContext } from '../../LayoutContext';

const getColors = (colorMode) => {
    const isLight = colorMode === 'light';
    return {
        pinkColor: isLight ? '#EC407A' : '#F48FB1',
        purpleColor: isLight ? '#AB47BC' : '#CE93D8',
        deeppurpleColor: isLight ? '#7E57C2' : '#B39DDB',
        indigoColor: isLight ? '#5C6BC0' : '#9FA8DA',
        blueColor: isLight ? '#42A5F5' : '#90CAF9',
        lightblueColor: isLight ? '#29B6F6' : '#81D4FA',
        cyanColor: isLight ? '#00ACC1' : '#4DD0E1',
        tealColor: isLight ? '#26A69A' : '#80CBC4',
        greenColor: isLight ? '#66BB6A' : '#A5D6A7',
        lightgreenColor: isLight ? '#9CCC65' : '#C5E1A5',
        limeColor: isLight ? '#D4E157' : '#E6EE9C',
        yellowColor: isLight ? 'FFEE58' : '#FFF59D',
        amberColor: isLight ? '#FFCA28' : '#FFE082',
        orangeColor: isLight ? '#FFA726' : '#FFCC80',
        deeporangeColor: isLight ? '#FF7043' : '#FFAB91',
        brownColor: isLight ? '#8D6E63' : '#BCAAA4'
    };
};

const getChartOptions = () => {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
    return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                display: true,
                labels: {
                    fontFamily,
                    color: textColor
                }
            }
        },
        scales: {
            y: {
                stacked: true,
                ticks: {
                    fontFamily,
                    color: textColor
                },
                grid: {
                    color: gridLinesColor
                }
            },
            x: {
                stacked: true,
                categoryPercentage: 0.9,
                barPercentage: 0.8,
                ticks: {
                    fontFamily,
                    color: textColor
                },
                grid: {
                    color: gridLinesColor
                }
            }
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    };
};

const Dashboard = (props) => {
    //const [, setProducts] = useState(null);
    const { isDesktop } = useContext(LayoutContext);
    //const isRTL = useContext(RTLContext);
    const bar = useRef(null);
    const [chartMonthlyData, setChartMonthlyData] = useState([]);
    const [chartMonthlyOptions, setChartMonthlyOptions] = useState(getChartOptions());
    const httpClient = useHttpClient();

    const dispatch = useDispatch();
    const issuer = useSelector((state) => state.dashboard.issuer);

    const onChange = (value) => {
        dispatch(
            updateIssuer({
                issuer: value
            })
        );
    };

    const onBlur = (value) => {};

    useEffect(() => {
        if (props.isNewThemeLoaded) {
            setChartMonthlyOptions(getChartOptions());
            props.onNewThemeChange(false);
        }
    }, [props.isNewThemeLoaded, props.onNewThemeChange]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isEmpty(issuer.code)) {
            getChartData(props.colorMode);
        }
    }, [issuer, props.colorMode]); // eslint-disable-line react-hooks/exhaustive-deps

    const getChartData = (colorMode) => {
        const { limeColor, blueColor, cyanColor, tealColor, greenColor } = getColors(colorMode);
        const now = new Date();
        httpClient
            .get(`admin/obtener/de/stats/${issuer.code}/${now.getFullYear()}`)
            .then((response) => {
                const datasets = [];
                response.data[0].borderColor = blueColor;
                response.data[0].backgroundColor = blueColor;
                response.data[0].borderWidth = 2;
                response.data[0].fill = true;

                datasets.push(response.data[0]);

                // response.data[1].borderColor = lightblueColor;
                // response.data[1].backgroundColor = lightblueColor;
                // response.data[1].borderWidth = 2;
                // response.data[1].fill = true;
                //
                // datasets.push(response.data[1]);

                // response.data[2].borderColor = cyanColor;
                // response.data[2].backgroundColor = cyanColor;
                // response.data[2].borderWidth = 2;
                // response.data[2].fill = true;
                //
                // datasets.push(response.data[2]);

                response.data[3].borderColor = tealColor;
                response.data[3].backgroundColor = tealColor;
                response.data[3].borderWidth = 2;
                response.data[3].fill = true;

                datasets.push(response.data[3]);

                response.data[4].borderColor = greenColor;
                response.data[4].backgroundColor = greenColor;
                response.data[4].borderWidth = 2;
                response.data[4].fill = true;

                datasets.push(response.data[4]);

                response.data[5].borderColor = cyanColor;
                response.data[5].backgroundColor = cyanColor;
                response.data[5].borderWidth = 2;
                response.data[5].fill = true;

                datasets.push(response.data[5]);

                response.data[6].borderColor = limeColor;
                response.data[6].backgroundColor = limeColor;
                response.data[6].borderWidth = 2;
                response.data[6].fill = true;

                datasets.push(response.data[6]);

                // response.data[7].borderColor = amberColor;
                // response.data[7].backgroundColor = amberColor;
                // response.data[7].borderWidth = 2;
                // response.data[7].fill = true;
                //
                // datasets.push(response.data[7]);
                setChartMonthlyData({
                    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                    datasets: datasets
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const changeMonthlyDataView = () => {
        let chart = bar.current.getChart();
        if (chart.options.scales.x.stacked) {
            chart.options.scales.x.stacked = false;
            chart.options.scales.y.stacked = false;
        } else {
            chart.options.scales.x.stacked = true;
            chart.options.scales.y.stacked = true;
        }

        bar.current.refresh();
    };

    return (
        <div className="grid dashboard flex align-items-center justify-content-center">
            <div className="col-12 md:col-8">
                <div className="card height-100">
                    <h5>Seleccione un emisor</h5>
                    <SeleccionarEmisor value={issuer} onChange={onChange} onBlur={onBlur} skeletonWidth={'12rem'} skeletonHeigh={'3rem'} placeHolder="Seleccione un emisor" />
                </div>
                <div className="card height-100">
                    <div className="card-header">
                        <h5>Comparación Mensual</h5>
                        <Button type="button" label="Datos apilados/Vertical" className={classNames('p-button-text', { 'ml-auto': isDesktop(), 'mr-auto': !isDesktop() })} onClick={changeMonthlyDataView}></Button>
                    </div>

                    <Chart ref={bar} type="bar" data={chartMonthlyData} options={chartMonthlyOptions}></Chart>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode && prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded && prevProps.onNewThemeChange === nextProps.onNewThemeChange;
};

export default React.memo(Dashboard, comparisonFn);
