import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from '../pages/Login/reducer';
import { isEmpty, isNull } from 'lodash';

const RouteGuard = ({ children }) => {
    const navigate = useNavigate();

    const [session, setSession] = useLocalStorage('token', null);

    const dispatch = useDispatch();

    const username = useSelector((state) => state.session.username);

    useEffect(() => {
        if (isNull(session)) {
            setSession(null);
            dispatch(logout());
            navigate('/');
            return;
        }

        if (!isNull(session) && isEmpty(username)) {
            const url = process.env.REACT_APP_BACKEND_URL + 'admin/me';
            axios
                .get(url, { headers: { Authorization: 'Bearer ' + session.token } })
                .then((response) => {
                    dispatch(login({ username: response.data.nombre_usuario, accesos: response.data.accesos, rol: response.data.rol }));
                })
                .catch((error) => {
                    setSession(null);
                    dispatch(logout());
                    navigate('/');
                });
        }
    }, [navigate, session, setSession, dispatch, username]);

    return children;
};

export default RouteGuard;
