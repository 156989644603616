import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import React from 'react';
import { FloatLabel } from 'primereact/floatlabel';

const GCamNCDE = (props) => {
    const motivoEmision = [
        {
            i_mot_emi: 1,
            d_des_mot_emi: 'Devolución y Ajuste de precios'
        },
        {
            i_mot_emi: 2,
            d_des_mot_emi: 'Devolución'
        },
        {
            i_mot_emi: 3,
            d_des_mot_emi: 'Descuento'
        },
        {
            i_mot_emi: 4,
            d_des_mot_emi: 'Bonificación'
        },
        {
            i_mot_emi: 5,
            d_des_mot_emi: 'Crédito incobrable'
        },
        {
            i_mot_emi: 6,
            d_des_mot_emi: 'Recupero de costo'
        },
        {
            i_mot_emi: 7,
            d_des_mot_emi: 'Recupero de gasto'
        },
        {
            i_mot_emi: 8,
            d_des_mot_emi: 'Ajuste de precio'
        }
    ];

    return (
        <>
            <div className="p-fluid formgrid grid">
                <Divider align="center">
                    <b>Campos que componen la Nota de Crédito/Débito Electrónica</b>
                </Divider>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <Dropdown
                            id="modEmisionNCND"
                            value={props.formik.values.mot_emision_ncnd}
                            onChange={(e) => props.formik.setFieldValue('mot_emision_ncnd', e.value)}
                            onBlur={() => props.formik.setFieldTouched('mot_emision_ncnd', true)}
                            options={motivoEmision}
                            optionLabel="d_des_mot_emi"
                            placeholder="Seleccione el motivo de la emisión"
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('mot_emision_ncnd') })}
                        />
                        <label htmlFor="modEmisionNCND">Motivo de la emisión</label>
                        {props.getFormErrorMessage('mot_emision_ncnd')}
                    </FloatLabel>
                </div>
            </div>
        </>
    );
};

export default GCamNCDE;
