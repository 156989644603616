import { useHttpClient } from '../hooks/useHttpClient';
import React, { useState } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const BuscarRUC = (props) => {
    const httpClient = useHttpClient();
    const [ruc, setRUC] = useState(props.value);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = () => {
        setIsLoading(true);
        httpClient
            .get(`api/ruc/${ruc}`)
            .then((response) => {
                if (response.data.d_cod_res === '0502') {
                    props.onSuccess(response.data.d_raz_cons);
                    setRUC(response.data.druc_cons);
                }
                if (response.data.d_cod_res === '0500') {
                    props.onNotFound();
                }

                setIsLoading(false);
            })
            .catch((error) => {
                props.onError();
                setIsLoading(false);
            });
    };

    const onChange = (val) => {
        setRUC(val);
        props.onChange(val);
    };

    return (
        <>
            {isLoading ? (
                <div className="field col-12 md:col-12">
                    <Skeleton id={props.id} height="3rem" className="mb-2"></Skeleton>
                </div>
            ) : (
                <React.Fragment>
                    <div className="p-inputgroup flex-4">
                        <InputText id={props.id} type="text" value={props.value} onChange={(e) => onChange(e.target.value)} className={props.className} />
                        <Button icon="pi pi-search" onClick={onSubmit}></Button>
                    </div>
                </React.Fragment>
            )}
        </>
    );
};

export default BuscarRUC;
