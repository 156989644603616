import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { FloatLabel } from 'primereact/floatlabel';
import { NATURALEZA_TRANSPORTISTA, TIPO_DOC_TRANSPORTISTA } from '../constants/transporte_mercaderias';

const GCamTrans = (props) => {
    return (
        <>
            <div className="p-fluid formgrid grid">
                <Divider align="center">
                    <b>Campos que identifican al transportista (persona física o jurídica)</b>
                </Divider>
                <div className="field col-12 md:col-3">
                    <FloatLabel>
                        <Dropdown
                            id="natTransportista"
                            value={props.formik.values.naturaleza_transportista}
                            onChange={(e) => props.formik.setFieldValue('naturaleza_transportista', e.value)}
                            onBlur={() => props.formik.setFieldTouched('naturaleza_transportista', true)}
                            options={NATURALEZA_TRANSPORTISTA}
                            optionLabel="d_des_nat_trans"
                            placeholder="Seleccione la naturaleza del transportista"
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('naturaleza_transportista') })}
                        />
                        <label htmlFor="natTransportista">Naturaleza del Transportista</label>
                        {props.getFormErrorMessage('naturaleza_transportista')}
                    </FloatLabel>
                </div>
                {props.formik.values.naturaleza_transportista?.i_nat_trans === 1 && (
                    <div className="field col-12 md:col-2">
                        <FloatLabel>
                            <InputText
                                id="rucTransportista"
                                value={props.formik.values.d_ruc_trans}
                                onChange={(e) => props.formik.setFieldValue('d_ruc_trans', e.target.value)}
                                onBlur={() => props.formik.setFieldTouched('d_ruc_trans', true)}
                                className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_ruc_trans') })}
                            />
                            <label htmlFor="rucTransportista">RUC</label>
                            {props.getFormErrorMessage('d_ruc_trans')}
                        </FloatLabel>
                    </div>
                )}
                {props.formik.values.naturaleza_transportista?.i_nat_trans === 2 && (
                    <>
                        <div className="field col-12 md:col-3">
                            <FloatLabel>
                                <Dropdown
                                    id="tipoDocTrans"
                                    value={props.formik.values.tipo_documento_transportista}
                                    onChange={(e) => props.formik.setFieldValue('tipo_documento_transportista', e.value)}
                                    onBlur={() => props.formik.setFieldTouched('tipo_documento_transportista', true)}
                                    options={TIPO_DOC_TRANSPORTISTA}
                                    optionLabel="dd_tip_id_trans"
                                    placeholder="Seleccione el tipo de documento de identidad del transportista"
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('tipo_documento_transportista') })}
                                />
                                <label htmlFor="tipoDocTrans">Tipo de documento de identidad</label>
                                {props.getFormErrorMessage('tipo_documento_transportista')}
                            </FloatLabel>
                        </div>
                        <div className="field col-12 md:col-3">
                            <FloatLabel>
                                <InputText
                                    id="nroIdentidadTransp"
                                    value={props.formik.values.d_num_id_trans}
                                    onChange={(e) => props.formik.setFieldValue('d_num_id_trans', e.target.value)}
                                    onBlur={() => props.formik.setFieldTouched('d_num_id_trans', true)}
                                    className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_num_id_trans') })}
                                />
                                <label htmlFor="nroIdentidadTransp">Número de identidad del transportista</label>
                                {props.getFormErrorMessage('d_num_id_trans')}
                            </FloatLabel>
                        </div>
                    </>
                )}
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <InputText
                            id="nombreTransp"
                            value={props.formik.values.d_nom_trans}
                            onChange={(e) => props.formik.setFieldValue('d_nom_trans', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('d_nom_trans', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_nom_trans') })}
                        />
                        <label htmlFor="nombreTransp">Nombre del transportista</label>
                        {props.getFormErrorMessage('d_nom_trans')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-3">
                    <FloatLabel>
                        <InputText
                            id="nroIdentidadChof"
                            value={props.formik.values.d_num_id_chof}
                            onChange={(e) => props.formik.setFieldValue('d_num_id_chof', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('d_num_id_chof', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_num_id_chof') })}
                        />
                        <label htmlFor="nroIdentidadChof">Número de identidad del chofer</label>
                        {props.getFormErrorMessage('d_num_id_chof')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-3">
                    <FloatLabel>
                        <InputText
                            id="nombreChofer"
                            value={props.formik.values.d_nom_chof}
                            onChange={(e) => props.formik.setFieldValue('d_nom_chof', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('d_nom_chof', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_nom_chof') })}
                        />
                        <label htmlFor="nombreChofer">Nombre del chofer</label>
                        {props.getFormErrorMessage('d_nom_chof')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-3">
                    <FloatLabel>
                        <InputText
                            id="dirFiscalTransp"
                            value={props.formik.values.d_dom_fisc}
                            onChange={(e) => props.formik.setFieldValue('d_dom_fisc', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('d_dom_fisc', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_dom_fisc') })}
                        />
                        <label htmlFor="dirFiscalTransp">Dirección fiscal del transportista</label>
                        {props.getFormErrorMessage('d_dom_fisc')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-3">
                    <FloatLabel>
                        <InputText
                            id="dirChoferTransp"
                            value={props.formik.values.d_dir_chof}
                            onChange={(e) => props.formik.setFieldValue('d_dir_chof', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('d_dir_chof', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_dir_chof') })}
                        />
                        <label htmlFor="dirChoferTransp">Dirección del chofer</label>
                        {props.getFormErrorMessage('d_dir_chof')}
                    </FloatLabel>
                </div>
            </div>
        </>
    );
};

export default GCamTrans;
