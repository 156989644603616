import axios from 'axios';
import { useLocalStorage } from './useLocalStorage';
import { useNavigate } from 'react-router-dom';

export const useHttpClient = () => {
    const [session] = useLocalStorage('token', null);

    const navigate = useNavigate();

    const defaultOptions = {
        baseURL: process.env.REACT_APP_BACKEND_URL
    };
    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        config.headers.Authorization = session ? `Bearer ${session.token}` : '';
        return config;
    });

    instance.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error.response.status === 401) {
                navigate('/');
                return;
            }
            return Promise.reject(error);
        }
    );
    return instance;
};
