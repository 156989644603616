import { classNames } from 'primereact/utils';
import React, { useContext, useRef } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { LayoutContext } from '../LayoutContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../pages/Login/reducer';
import { useLocalStorage } from '../hooks/useLocalStorage';

const AppMenuProfile = () => {
    const { layoutState, layoutConfig, isSlim, isHorizontal, onMenuProfileToggle } = useContext(LayoutContext);
    const navigate = useNavigate();
    const ulRef = useRef(null);
    const dispatch = useDispatch();
    const [, setSession] = useLocalStorage('token', null);

    const doLogout = () => {
        dispatch(logout());
        setSession(null);
        navigate('/');
    };

    const username = useSelector((state) => state.session.username);

    const hiddenClassName = classNames({ hidden: layoutConfig.menuMode === 'drawer' && !layoutState.sidebarActive });

    const toggleMenu = () => {
        if (layoutState.menuProfileActive) {
            setTimeout(() => {
                ulRef.current.style.maxHeight = '0';
            }, 1);
            ulRef.current.style.opacity = '0';
            if (isHorizontal()) {
                ulRef.current.style.transform = 'scaleY(0.8)';
            }
        } else {
            setTimeout(() => {
                ulRef.current.style.maxHeight = ulRef.current.scrollHeight.toString() + 'px';
            }, 1);
            ulRef.current.style.opacity = '1';
            if (isHorizontal()) {
                ulRef.current.style.transform = 'scaleY(1)';
            }
        }
        onMenuProfileToggle();
    };

    const tooltipValue = (tooltipText: string) => {
        return isSlim() ? tooltipText : null;
    };

    return (
        <React.Fragment>
            <div className="layout-menu-profile">
                <Tooltip target={'.avatar-button'} content={tooltipValue('Profile')} />
                <button className="avatar-button p-link" onClick={toggleMenu}>
                    <i className="pi fs-large pi-user" alt="avatar" style={{ width: '28px', height: '15px', marginLeft: '0' }} />
                    <span>
                        <strong>{username}</strong>
                    </span>
                    <i
                        className={classNames('layout-menu-profile-toggler pi pi-fw', {
                            'pi-angle-down': layoutConfig.menuProfilePosition === 'start' || isHorizontal(),
                            'pi-angle-up': layoutConfig.menuProfilePosition === 'end' && !isHorizontal()
                        })}
                    ></i>
                </button>

                <ul ref={ulRef} className={classNames('menu-transition', { overlay: isHorizontal() })} style={{ overflow: 'hidden', maxHeight: 0, opacity: 0 }}>
                    {layoutState.menuProfileActive && (
                        <>
                            <li>
                                <button className="p-link" onClick={() => doLogout()}>
                                    <i className="pi pi-power-off pi-fw"></i>
                                    <span className={hiddenClassName}>Cerrar sesión</span>
                                </button>
                            </li>
                        </>
                    )}
                </ul>
            </div>
        </React.Fragment>
    );
};

export default AppMenuProfile;
