import React from 'react';
import GCamFE from './GCamFE';
import GCamCond from '../GCamCond';
import GCamItem from './GCamItem';
import GCamNRE from './GCamNRE';
import GTransp from './GTransp';
import GCamSal from './GCamSal';
import GCamEnt from './GCamEnt';
import GVehTras from './GVehTras';
import GCamTrans from './GCamTrans';
import GCamNCDE from './GCamNCDE';

const GDTipDE = (props) => {
    const renderComponent = () => {
        return (
            <>
                {props.formik.values.tipo_de?.i_ti_de === 1 && <GCamFE formik={props.formik} getFormErrorMessage={props.getFormErrorMessage} isFormFieldInvalid={props.isFormFieldInvalid} />}
                {(props.formik.values.tipo_de?.i_ti_de === 1 || props.formik.values.tipo_de?.i_ti_de === 4) && <GCamCond formik={props.formik} getFormErrorMessage={props.getFormErrorMessage} isFormFieldInvalid={props.isFormFieldInvalid} />}
                {props.formik.values.tipo_de?.i_ti_de === 7 && <GCamNRE formik={props.formik} getFormErrorMessage={props.getFormErrorMessage} isFormFieldInvalid={props.isFormFieldInvalid} />}
                {props.formik.values.tipo_de?.i_ti_de === 7 && <GTransp formik={props.formik} getFormErrorMessage={props.getFormErrorMessage} isFormFieldInvalid={props.isFormFieldInvalid} />}
                {props.formik.values.tipo_de?.i_ti_de === 7 && <GCamSal formik={props.formik} getFormErrorMessage={props.getFormErrorMessage} isFormFieldInvalid={props.isFormFieldInvalid} />}
                {props.formik.values.tipo_de?.i_ti_de === 7 && <GCamEnt formik={props.formik} getFormErrorMessage={props.getFormErrorMessage} isFormFieldInvalid={props.isFormFieldInvalid} />}
                {props.formik.values.tipo_de?.i_ti_de === 7 && <GVehTras formik={props.formik} getFormErrorMessage={props.getFormErrorMessage} isFormFieldInvalid={props.isFormFieldInvalid} />}
                {props.formik.values.tipo_de?.i_ti_de === 7 && <GCamTrans formik={props.formik} getFormErrorMessage={props.getFormErrorMessage} isFormFieldInvalid={props.isFormFieldInvalid} />}
                {(props.formik.values.tipo_de?.i_ti_de === 5 || props.formik.values.tipo_de?.i_ti_de === 6) && <GCamNCDE formik={props.formik} getFormErrorMessage={props.getFormErrorMessage} isFormFieldInvalid={props.isFormFieldInvalid} />}
                <GCamItem formik={props.formik} getFormErrorMessage={props.getFormErrorMessage} isFormFieldInvalid={props.isFormFieldInvalid} onError={props.onError} isEditing={props.isEditing} fieldsLoading={props.fieldsLoading} />
            </>
        );
    };

    return renderComponent();
};

export default GDTipDE;
