import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { LayoutContext } from '../LayoutContext';
import { Ripple } from 'primereact/ripple';
import { Link } from 'react-router-dom';

const AppTopbar = forwardRef((props, ref) => {
    const { onMenuToggle, onTopbarMenuToggle } = useContext(LayoutContext);
    const menubuttonRef = useRef(null);
    const mobileButtonRef = useRef(null);

    const onMenuButtonClick = () => {
        onMenuToggle();
    };
    const onMobileTopbarMenuButtonClick = () => {
        onTopbarMenuToggle();
    };

    useImperativeHandle(ref, () => ({
        menubutton: menubuttonRef.current
    }));

    return (
        <div className="layout-topbar">
            <div className="layout-topbar-start">
                <Link className="layout-topbar-logo" to="/home">
                    <img id="app-logo" src="/layout/images/logo/logo-light.svg" alt="app-logo" style={{ height: '2.25rem', width: '14rem' }} />
                </Link>
                {/* eslint-disable-next-line*/}
                <a ref={menubuttonRef} className="p-ripple layout-menu-button" onClick={onMenuButtonClick}>
                    <i className="pi pi-chevron-right"></i>
                    <Ripple />
                </a>
                {/* eslint-disable-next-line*/}
                <a ref={mobileButtonRef} className="p-ripple layout-topbar-mobile-button" onClick={onMobileTopbarMenuButtonClick}>
                    <i className="pi pi-ellipsis-v"></i>
                    <Ripple />
                </a>
            </div>
        </div>
    );
});

AppTopbar.displayName = 'AppTopbar';

export default AppTopbar;
