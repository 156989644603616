import { MultiSelect } from 'primereact/multiselect';

const ImpuestoAfectadoRG90 = (props) => {
    const listaTipoImpuestoAfectadoRG90 = [
        {
            c_obl_afe: 113,
            d_des_obl_afe: 'IMPUESTO A LA RENTA IRACIS - REGÍMENES ESPECIALES'
        },
        {
            c_obl_afe: 143,
            d_des_obl_afe: 'TRIBUTO UNICO MAQUILA'
        },
        {
            c_obl_afe: 211,
            d_des_obl_afe: 'IMPUESTO AL VALOR AGREGADO - GRAVADAS Y EXONERADAS - EXPORTADORES'
        },
        {
            c_obl_afe: 311,
            d_des_obl_afe: 'IMPUESTO SELECTIVO AL CONSUMO - GENERAL'
        },
        {
            c_obl_afe: 321,
            d_des_obl_afe: 'IMPUESTO SELECTIVO AL CONSUMO COMBUSTIBLES'
        },
        {
            c_obl_afe: 700,
            d_des_obl_afe: 'IMPUESTO A LA RENTA EMPRESARIAL - RÉGIMEN GENERAL'
        },
        {
            c_obl_afe: 701,
            d_des_obl_afe: 'IMPUESTO A LA RENTA EMPRESARIAL - SIMPLE'
        },
        {
            c_obl_afe: 702,
            d_des_obl_afe: 'IMPUESTO A LA RENTA EMPRESARIAL - RESIMPLE'
        },
        {
            c_obl_afe: 703,
            d_des_obl_afe: 'IMPUESTO DE ZONA FRANCA'
        },
        {
            c_obl_afe: 715,
            d_des_obl_afe: 'IMPUESTO A LA RENTA PERSONAL - SERVICIOS PERSONALES'
        },
        {
            c_obl_afe: 716,
            d_des_obl_afe: 'IMPUESTO A LA RENTA PERSONAL - RENTAS Y GANANCIAS DE CAPITAL'
        }
    ];

    const template = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.c_obl_afe + '-' + option.d_des_obl_afe}</div>
            </div>
        );
    };

    const panelFooterTemplate = () => {
        const length = props.value ? props.value.length : 0;

        return (
            <div className="py-2 px-3">
                <b>{length}</b> impuesto{length > 1 ? 's' : ''} seleccionados.
            </div>
        );
    };

    return (
        <MultiSelect
            id={props.id}
            value={props.value}
            filter
            options={listaTipoImpuestoAfectadoRG90}
            itemTemplate={template}
            panelFooterTemplate={panelFooterTemplate}
            onChange={(e) => props.onChange(e.value)}
            onBlur={() => props.onBlur()}
            optionLabel="d_des_obl_afe"
            display="chip"
            placeholder="Seleccione el tipo de impuesto afectado"
            className={props.className}
        />
    );
};

export default ImpuestoAfectadoRG90;
