import React from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    const goDashboard = () => {
        navigate('/home');
    };

    return (
        <div className="h-screen flex flex-column bg-cover" style={{ background: 'url(/layout/images/pages/login-bg.jpg)' }}>
            <div className="align-self-center mt-auto mb-auto">
                <div className="text-center z-4 border-round-lg border-1 surface-border bg-white p-3 shadow-3 flex flex-column">
                    <div className="border-round-md mx-auto border-1 surface-border bg-bluegray-700 px-3 py-1">
                        <h2 className="m-0 text-white">No Encontrado</h2>
                    </div>
                    <div className="surface-200 p-3 mb-5 shadow-2 border-round-md mt-3 px-6">
                        <img src="/layout/images/pages/404.png" className="w-full" alt="" />
                    </div>
                    <div className="text-color-secondary pb-6">El recurso solicitado no se encuentra disponible.</div>
                    <Button label="Vuelve a la página de inicio" text onClick={goDashboard}></Button>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
