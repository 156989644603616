import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import SeleccionarDepartamento from '../../../../components/SeleccionarDepartamento';
import SeleccionarDistrito from '../../../../components/SeleccionarDistrito';
import SeleccionarLocalidad from '../../../../components/SeleccionarLocalidad';
import React from 'react';
import { FloatLabel } from 'primereact/floatlabel';

const GCamEnt = (props) => {
    return (
        <>
            <div className="p-fluid formgrid grid">
                <Divider align="center">
                    <b>Campos que identifican el local de entrega de las mercaderías</b>
                </Divider>
                <div className="field col-12 md:col-8">
                    <FloatLabel>
                        <InputText
                            id="dirLocEnt"
                            value={props.formik.values.d_dir_loc_ent}
                            onChange={(e) => props.formik.setFieldValue('d_dir_loc_ent', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('d_dir_loc_ent', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_dir_loc_ent') })}
                        />
                        <label htmlFor="dirLocEnt">Dirección</label>
                        {props.getFormErrorMessage('d_dir_loc_ent')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <InputText
                            id="numCasaEnt"
                            value={props.formik.values.d_num_cas_ent}
                            onChange={(e) => props.formik.setFieldValue('d_num_cas_ent', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('d_num_cas_sal', true)}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('d_num_cas_ent') })}
                        />
                        <label htmlFor="numCasaEnt">Número de casa</label>
                        {props.getFormErrorMessage('d_num_cas_ent')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <SeleccionarDepartamento
                            id="dptoLocalEntrega"
                            value={props.formik.values.dpto_local_entrega}
                            onChange={(e) => props.formik.setFieldValue('dpto_local_entrega', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('dpto_local_entrega', true)}
                            skeletonHeigh={'3rem'}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('dpto_local_entrega') })}
                        />
                        <label htmlFor="dptoLocalEntrega">Departamento</label>
                        {props.getFormErrorMessage('dpto_local_entrega')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <SeleccionarDistrito
                            id="distLocalSalida"
                            departamento={props.formik.values.dpto_local_entrega?.codigo}
                            value={props.formik.values.dist_local_entrega}
                            onChange={(e) => props.formik.setFieldValue('dist_local_entrega', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('dist_local_entrega', true)}
                            skeletonHeigh={'3rem'}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('dist_local_entrega') })}
                        />
                        <label htmlFor="distLocalSalida">Distrito</label>
                        {props.getFormErrorMessage('dist_local_entrega')}
                    </FloatLabel>
                </div>
                <div className="field col-12 md:col-4">
                    <FloatLabel>
                        <SeleccionarLocalidad
                            id="locLocalSalida"
                            distrito={props.formik.values.dist_local_entrega?.codigo}
                            value={props.formik.values.loc_local_entrega}
                            onChange={(e) => props.formik.setFieldValue('loc_local_entrega', e.target.value)}
                            onBlur={() => props.formik.setFieldTouched('loc_local_entrega', true)}
                            skeletonHeigh={'3rem'}
                            className={classNames({ 'p-invalid': props.isFormFieldInvalid('loc_local_entrega') })}
                        />
                        <label htmlFor="locLocalSalida">Localidad</label>
                        {props.getFormErrorMessage('loc_local_entrega')}
                    </FloatLabel>
                </div>
            </div>
        </>
    );
};

export default GCamEnt;
