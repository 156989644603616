export const RESPONSABLE_COSTO_FLETE = [
    {
        i_resp_flete: 1,
        d_des_resp_flete: 'Emisor de la Factura Electrónica'
    },
    {
        i_resp_flete: 2,
        d_des_resp_flete: 'Receptor de la Factura Electrónica'
    },
    {
        i_resp_flete: 3,
        d_des_resp_flete: 'Tercero'
    },
    {
        i_resp_flete: 4,
        d_des_resp_flete: 'Agente intermediario del transporte (cuando intervenga)'
    },
    {
        i_resp_flete: 5,
        d_des_resp_flete: 'Transporte propio'
    }
];

export const TIPO_TRANSPORTE = [
    {
        i_tip_trans: 1,
        d_des_tip_trans: 'Propio'
    },
    {
        i_tip_trans: 1,
        d_des_tip_trans: 'Tercero'
    }
];

export const MODALIDA_TRANSPORTE = [
    {
        i_mod_trans: 1,
        d_des_mod_trans: 'Terrestre'
    },
    {
        i_mod_trans: 2,
        d_des_mod_trans: 'Fluvial'
    },
    {
        i_mod_trans: 3,
        d_des_mod_trans: 'Aéreo'
    },
    {
        i_mod_trans: 4,
        d_des_mod_trans: 'Multimodal'
    }
];

export const CONDICION_NEGOCIACION = [
    {
        c_cond_neg: 'CFR',
        d_des_cond_neg: 'Costo y flete'
    },
    {
        c_cond_neg: 'CIF',
        d_des_cond_neg: 'Costo, seguro y flete'
    },
    {
        c_cond_neg: 'CIP',
        d_des_cond_neg: 'Transporte y seguro pagados hasta'
    },
    {
        c_cond_neg: 'CPT',
        d_des_cond_neg: 'Transporte pagado hasta'
    },
    {
        c_cond_neg: 'DAP',
        d_des_cond_neg: 'Entregada en lugar convenido'
    },
    {
        c_cond_neg: 'DAT',
        d_des_cond_neg: 'Entregada en terminal'
    },
    {
        c_cond_neg: 'DDP',
        d_des_cond_neg: 'Entregada derechos pagados'
    },
    {
        c_cond_neg: 'EXW',
        d_des_cond_neg: 'En fabrica'
    },
    {
        c_cond_neg: 'FAS',
        d_des_cond_neg: 'Franco al costado del buque'
    },
    {
        c_cond_neg: 'FCA',
        d_des_cond_neg: 'Franco transportista'
    },
    {
        c_cond_neg: 'FOB',
        d_des_cond_neg: 'Franco a bordo'
    }
];

export const TIPO_IDENTIFICACION_VEHICULO = [
    {
        d_tip_iden_veh: 1,
        d_des_tip_iden_veh: 'Número de identificación del vehículo'
    },
    {
        d_tip_iden_veh: 2,
        d_des_tip_iden_veh: 'Número de matrícula del vehículo'
    }
];

export const NATURALEZA_TRANSPORTISTA = [
    {
        i_nat_trans: 1,
        d_des_nat_trans: 'Contribuyente'
    },
    {
        i_nat_trans: 2,
        d_des_nat_trans: 'No contribuyente'
    }
];

export const TIPO_DOC_TRANSPORTISTA = [
    {
        i_tip_id_trans: 1,
        dd_tip_id_trans: 'Cédula paraguaya'
    },
    {
        i_tip_id_trans: 2,
        dd_tip_id_trans: 'Pasaporte'
    },
    {
        i_tip_id_trans: 3,
        dd_tip_id_trans: 'Cédula extranjera'
    },
    {
        i_tip_id_trans: 4,
        dd_tip_id_trans: 'Carnet de residencia'
    }
];
